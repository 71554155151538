import {useContext} from 'react';

import appContext from '@/appContext.tsx';

import {
    CatalogFilters,
    CatalogInfo,
    CatalogMenu,
    Footer,
    Header,
    ProductsGrid,
} from '@components/export';

import {Skeleton} from '@mui/material';

export const CatalogPage = () => {
    // todo: pass render data as props

    const context = useContext(appContext).catalogContext;

    const options = context.menu;
    const query = context.query;

    const {filters} = useContext(appContext).productsContext;

    const purpose = filters.purpose || options[0].name;

    return <>
        <Header/>

        <div className="page-content catalog-page">
            <CatalogMenu options={query.isFetched && Array.isArray(options) ? options : [{name: '', label: ''}]}/>
            {
                query.isFetched && Array.isArray(options) ?
                    <>
                        <div className="catalog-page__container container">
                            <CatalogFilters purpose={purpose}/>
                            <ProductsGrid limit={12} isDemo={false} mode={'catalog'} purpose={purpose} useFilters={true}/>
                        </div>

                        <CatalogInfo/>
                    </> :
                    <Skeleton width={'100%'} height={'200px'}/>
            }
        </div>

        <Footer/>
    </>;
};
