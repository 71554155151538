import {useLabel} from '@/hooks/export';
import {BigTitle, CatalogInfo, Footer, Header, MachineRequestForm} from '@components/export';

export const MachineRequestPage = () => {
    const label = useLabel();

    return <>
        <Header/>

        <div className="page-content machine-request-page">
            <div className="container">
                <BigTitle title={label.contactForm} dark={true} className={''}/>
                <MachineRequestForm />
            </div>

            <CatalogInfo/>
        </div>

        <Footer/>
    </>;
};
