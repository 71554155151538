import {Header, Footer} from '@components/export';
import utils from 'utils';
import {useNavigate} from 'react-router-dom';
import {useLabel} from '@/hooks/useLabel';
import {UiButton} from '@components/ui/typo/UiButton';

export function NotFound() {
    const navigate = useNavigate();
    const label = useLabel();

    return (<>
        <Header/>

        <div className="page-content error-page">
            <div className="container">

                <div className={classMap.container}>
                    <div className={classMap.box}>
                        <p className={`${classMap.text} ${classMap.textBig}`}>
                            {label.pageNotFound}
                            <span className={classMap.divider}></span>
                        </p>
                        <span className={`${classMap.text} ${classMap.textSmall}`}>
                            <span dangerouslySetInnerHTML={{__html: label.pageNotFoundContent}}></span>
                        </span>
                        <UiButton
                            onClick={() => navigate('/')}
                            text={label.backToHome}
                            style={2}
                        />
                    </div>
                </div>

            </div>
        </div>

        <Footer/>
    </>);
}

const cmpClass = 'error-handler';
const classMap = utils.generateClassMap(cmpClass, {
    container: 'container container',
    box: 'box',
    text: 'text',
    textBig: 'text--big',
    textSmall: 'text--small',
    menuLink: 'menu-link',
    divider: 'divider',
});
