import {useParams} from 'react-router';

import {useApiQuery} from '@/api';
import {Footer, BigTitle, Header} from '@components/export';

export const WikiPage = () => {
    const params = useParams();

    const pagesIdMap = {
        'o-nas': '6bc20b3c-f928-8226-f6f1-66c8675b5bda',
        'montaz-a-lisovani-pneumatik': '62819d6f-c834-638d-2789-66c877f20e8a',
        'vseobecne-obchodni-podminky': '919cd379-e3ee-2962-e5ce-66c878c33685',
        'zpracovani-osobnich-udaju': '876f342f-79bb-b347-a3fc-66c878573751',
        'reklamacni-rad': '28d6ed2f-94c8-5e6b-a94a-66c878b56118',
        'obchodni-oddeleni': '10c36d71-7367-4d0d-6706-66c879077bf2',
    };

    // todo: explain 'as'
    const query = useApiQuery('getWiki', {id: pagesIdMap[params.name]}) as {
        data: {
            article: string,
            name: string,
        }
    };

    return <>
        <Header/>

        <div className="page-content wiki-page container">
            {
                query !== undefined && query.data?.article ?
                    <div className={'wiki-page__content-wrapper'}>
                        <BigTitle title={query.data.name} className={''} dark={true} />
                        <div
                            className={'wiki-page__content'}
                            dangerouslySetInnerHTML={{__html: query.data.article}}
                        ></div>
                    </div> :
                    <></>
            }
        </div>

        <Footer/>
    </>;
};
