import {useLabel} from '@/hooks/export';

import categoryImg1 from '@img/cat_1_VZV.png';
import categoryImg2 from '@img/cat_2_Stavebni.png';
import categoryImg5 from '@img/cat_3_Zemedelske.png';
import categoryImg6 from '@img/cat_4_vidlice.png';

export const useEshopConfig = () => {
    // todo: move here static variables at components' top

    const label = useLabel();

    return {
        cartContactForm: {
            layout: [
                {
                    title: 'Fakturační adresa',
                    name: 'invoiceAddress',
                    grid: [
                        {name: 'info', type: 'text', label: ''},
                        {name: 'info2', type: 'text', label: ''},
                        {name: 'invoiceCompany', type: 'inputText', label: label.company},
                        {name: 'invoiceCity', type: 'inputText', label: label.city},
                        {name: 'invoiceStreet', type: 'inputText', label: label.street},
                        {name: 'invoicePostalCode', type: 'inputText', label: label.postalCode},
                    ],
                },
                {
                    title: 'Adresa provedení',
                    name: 'execAddress',
                    grid: [
                        {name: 'savedAddress', type: 'select', label: label.selectSavedAddress},
                        {name: 'doSaveAddress', type: 'checkbox', label: label.doSaveAddress},
                        {name: 'execCompany', type: 'inputText', label: label.company},
                        {name: 'execCity', type: 'inputText', label: label.city},
                        {name: 'execStreet', type: 'inputText', label: label.street},
                        {name: 'execPostalCode', type: 'inputText', label: label.postalCode},
                    ],
                },
                {
                    title: 'Kontaktní osoba',
                    name: 'contact',
                    grid: [
                        {name: 'savedContact', type: 'select', label: label.selectSavedContact},
                        {name: 'doSaveContact', type: 'checkbox', label: label.doSaveContact},
                        {name: 'contactFirstName', type: 'inputText', label: label.firstName},
                        {name: 'contactLastName', type: 'inputText', label: label.lastName},
                        {name: 'contactEmail', type: 'inputText', label: label.email},
                        {name: 'contactPhone', type: 'inputText', label: label.phone},
                    ],
                },
            ],
        },
        userRegisterForm: {
            layout: [
                {
                    title: 'Firemní údaje',
                    name: 'company',
                    grid: [
                        {name: 'companyIco', type: 'inputText', label: label.ico, required: true},
                        {name: 'companyName', type: 'inputText', label: label.company, required: true},
                        {name: 'companyEmail', type: 'inputText', label: label.email, required: true},
                        {name: 'companyPhone', type: 'inputText', label: label.phone, required: true},
                    ],
                },
                {
                    title: 'Fakturační adresa',
                    name: 'invoice',
                    grid: [
                        {name: 'invoiceCity', type: 'inputText', label: label.city, required: true},
                        {name: 'invoiceStreet', type: 'inputText', label: label.street, required: true},
                        {name: 'invoicePostalCode', type: 'inputText', label: label.postalCode, required: true},
                        {name: 'invoiceCountry', type: 'select', label: label.state, required: true},
                    ],
                },
                {
                    title: 'Kontaktní osoba',
                    name: 'contact',
                    grid: [
                        {name: 'contactFirstName', type: 'inputText', label: label.firstName, required: true},
                        {name: 'contactLastName', type: 'inputText', label: label.lastName, required: true},
                        {name: 'contactPhone', type: 'inputText', label: label.phone, required: true},
                        {name: 'contactEmail', type: 'inputText', label: label.email, required: true},
                        {name: 'contactJobTitle', type: 'select', label: label.jobTitle, required: false},
                    ],
                },
            ],
        },
        categoryImgMap: {
            1: categoryImg2,
            2: categoryImg1,
            3: categoryImg5,
            4: categoryImg6,
            // 4: category4,
        },
    };
};
