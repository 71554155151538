import utils from 'utils';
import {RenderImage} from '@components/export';

export const CategoryCard = (props: {
    img: string,
    name: string,
    label: string,
    onClick: (name: string) => void,
}) => {
    const data = {
        img: props.img,
        name: props.name,
        label: props.label,
    };

    return <div
        onClick={() => props.onClick(props.name)}
        className={cmpClass}
    >
        <div className={classMap.imgBox}>
            <RenderImage
                className={classMap.img}
                containerClassName={classMap.imgContainer}
                src={data.img}
                alt=""
            />
        </div>
        <button className={classMap.button}>
            {data.label}
        </button>
    </div>;
};

const cmpClass = 'category-card';
const classMap = utils.generateClassMap(cmpClass, {
    imgBox: 'img-box',
    img: 'img',
    button: 'button',
    imgContainer: 'img-container',
});
