import {

    Footer,
    Header, UserProfile,
} from '@components/export';

export const ProfilePage = () => {
    return <>
        <Header/>

        <div className="page-content profile-page">
            <div className="profile-page-info container">
                <UserProfile/>
            </div>
        </div>

        <Footer/>
    </>;
};
