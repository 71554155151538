import {useApiQuery} from '@/api';

export const useCatalog = (props?: {
    enabled?: boolean
}) => {
    const query = useApiQuery('getPurposeList', {}, !props?.enabled);

    return {
        menu: query.data !== undefined ? query.data : [{name: '', label: ''}],
        query: query,
    };
};
