import utils from 'utils';

export const CatalogInfo = () => {
    const data = {
        text1: 'V případě dotazů ohledně našich produktů a služeb nás neváhejte kontaktovat.',
        text2: '',
        phone: '+420 596 013 541',
        email: 'cemat@cemat.cz',
        title: 'Rádi Vám pomůžeme',
    };

    return <div className={cmpClass}>
        <div className={classMap.container}>
            <div className={classMap.box}>
                <span className={classMap.title}>
                    {data.title}
                </span>
                <p className={classMap.text}>
                    {data.text1}
                </p>
            </div>
            <div className={classMap.box}>
                <div className={classMap.links}>
                    <a className={classMap.link} href="tel:+420596013541">
                        <i className={classMap.iconPhone}></i>
                        <span>{data.phone}</span>
                    </a>
                    <a className={classMap.link} href="mailto:cemat@cemat.cz">
                        <i className={classMap.iconEmail}></i>
                        <span>{data.email}</span>
                    </a>
                </div>
                <p className={classMap.text}>
                    {data.text2}
                </p>
            </div>
        </div>
    </div>;
};

const cmpClass = 'catalog-info';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    container: 'container container',
    iconEmail: 'icon iconfa-email',
    iconPhone: 'icon iconfa-phone',
    link: 'link',
    links: 'links',
    text: 'text',
    title: 'title',
});
