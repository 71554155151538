import {useNavigate} from 'react-router-dom';

import companyLogoImg from '@img/company-logo.svg';

export const CompanyLogo = () => {
    const navigate = useNavigate();

    const data = {
        src: companyLogoImg,
    };

    return <div
        className={cmpClass}
        onClick={() => navigate('/welcome')}
    >
        <img src={data.src} alt=""/>
    </div>;
};

const cmpClass = 'company-logo';
