import utils from 'utils';
import {api} from '@/api';

import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {BigTitle, UiButton, UiSelect} from '@components/export';
import {useEshopConfig} from '@/hooks/export';

import {TextField} from '@mui/material';
import toast from 'react-hot-toast';

import {useLabel} from '@/hooks/export';

export const UserRegister = () => {
    const {layout} = useEshopConfig().userRegisterForm;
    const label = useLabel();
    const navigate = useNavigate();

    // const [loading, setLoading] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [fields, setFields] = useState({
        companyIco: '',
        companyName: '',
        companyEmail: '',
        companyPhone: '',
        invoiceCity: '',
        invoiceStreet: '',
        invoicePostalCode: '',
        invoiceCountry: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        contactEmail: '',
        contactJobTitle: '',
    });

    const handle = {
        getAccountByICO(ico) {
            api.company.checkIco(ico)
                .then((response) => {
                    if (!response.companyName) {
                        return;
                    }

                    this.inputChange(response.companyName || '', 'companyName');
                    this.inputChange(response.companyEmail || '', 'companyEmail');
                    this.inputChange(response.invoiceStreet || '', 'invoiceStreet');
                    this.inputChange(response.invoicePostalCode || '', 'invoicePostalCode');
                    this.inputChange(response.invoiceCity || '', 'invoiceCity');
                });
        },
        inputChange(value: string, name: string) {
            fields[name] = value;
            setFields({...fields});
        },
        userRegister() {
            // check all required fields
            for (const group of layout) {
                for (const fieldData of group.grid) {
                    if (fieldData.required && !fields[fieldData.name]) {
                        setShowErrors(true);

                        toast.error(label.requiredField);
                        return;
                    }
                }
            }

            return api.user.register(fields)
                .then((ret) => {
                    if (!ret?.status) {
                        toast.error(label[ret?.message]);
                        return;
                    }
                    toast.success(label.accepted);
                    navigate('/login');
                });
        },
    };

    return <div className={cmpClass}>
        <BigTitle title={label.accessToB2B} dark={true} className={''} />
        {
            layout.map((group, groupI) => {
                return <div className={classMap.group} key={groupI}>
                    <p className={classMap.title}>
                        {group.title}
                    </p>

                    <div className={classMap.grid}>
                        {
                            group.grid.map((item, itemI) => {
                                return <div className={classMap.gridItem} key={itemI}>
                                    <div className={classMap[item.type]}>
                                        {
                                            getGridItemJSX(
                                                item,
                                                ({name, value}) => {
                                                    handle.inputChange(value, name);

                                                    if (name === 'companyIco' && value.length === 8 && !isNaN(value)) {
                                                        handle.getAccountByICO(value);
                                                    }
                                                },
                                                fields,
                                                showErrors,
                                            )
                                        }
                                    </div>
                                </div>;
                            })
                        }
                    </div>
                </div>;
            })
        }
        <div className={classMap.button}>
            <UiButton
                style={1}
                text={label.sendRequest}
                onClick={() => handle.userRegister()}
                // loading={loading}
            />
        </div>
    </div>;
};

const cmpClass = 'user-register';
const classMap = utils.generateClassMap(cmpClass, {
    title: 'title',
    box: 'box',
    fields: 'fields',
    field: 'field',
    action: 'action',
    button: 'button',
    link: 'link',
    request: 'request',
    grid: 'grid',
});

// todo: move to new component?
const getGridItemJSX = (item, onChange, fields, showErrors) => {
    const options = [];
    let selectRequired = false;

    switch (item.type) {
        case 'inputText':
            return <TextField
                name={item.name}
                label={item.label}
                size={'small'}
                value={fields[item.name]}
                fullWidth
                onChange={(e) => {
                    onChange({
                        name: item.name,
                        value: e.target.value,
                    });
                }}
                required={true}
                error={!fields[item.name] && showErrors}
            />;
        case 'select':
            if (item.name === 'invoiceCountry') {
                options.push({value: 'cz', label: 'Česká republika'});
                options.push({value: 'sk', label: 'Slovenská republika'});

                selectRequired = true;
            }

            if (item.name === 'contactJobTitle') {
                options.push({value: '', label: ''});
                options.push({value: 'manager', label: 'Manažer'});
            }

            return <UiSelect
                name={item.name}
                label={item.label}
                value={fields[item.name] || ''}
                onChange={(value) => {
                    onChange({name: item.name, value: value});
                }}
                options={options}
                required={selectRequired}
            />;
        // case 'text':
        //     return <p>{fields[item.name]}</p>;
        // case 'checkbox':
        //     return <UiCheckbox
        //         onChange={(checked) => {
        //             onChange({
        //                 name: item.name,
        //                 value: checked,
        //             });
        //         }}
        //         label={item.label}
        //         defaultChecked={fields[item.name]}
        //     />;
        default:
            return <></>;
    }
};
