import {Outlet} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorHandler from '@components/ui/error/ErrorHandler';

export default function ErrorBoundaryLayout() {
    return (
        <ErrorBoundary FallbackComponent={ErrorHandler}>
            <Outlet />
        </ErrorBoundary>
    );
}
