import utils from 'utils';

import {useState, useEffect} from 'react';

export const CountInput = (props: {
    initCount: number,
    currentCount?: number,
    onChange: (counter: number) => void,
    max?: number,
    readonly?: boolean,
}) => {
    // todo: refactor?

    const [count, setCount] = useState(props.initCount);
    const [loaded, setLoaded] = useState(false);

    if (props.max === 0) {
        if (count !== 0) {
            setCount(0);
        }
    } else {
        if (count < 1) {
            setCount(props.currentCount || 1);
        } else if (count > 99) { // fixme: in some components max value is redundant
            setCount(99);
        }
    }

    useEffect(() => {
        setLoaded(true);
    }, []);

    useEffect(() => {
        if (!loaded) {
            return;
        }

        if ((count || props.max === 0) && props.onChange) {
            props.onChange(count);
        }
    }, [count]);

    useEffect(() => {
        if (props.currentCount) {
            setCount(props.currentCount);
        }
    }, [props.currentCount]);

    return <div className={cmpClass}>
        <span
            onClick={() => !props.readonly ? setCount(count - 1) : {}}
            className={props.readonly ? classMap.hidden : ''}
        >
            {'-'}
        </span>
        <input
            type="number"
            value={count === 0 ? '' : count}
            onChange={(e) => setCount(+e.target.value)}
            disabled={props.readonly}
        />
        <span
            onClick={() => !props.readonly ? setCount(count + 1) : {}}
            className={props.readonly ? classMap.hidden : ''}
        >
            {'+'}
        </span>
    </div>;
};

const cmpClass = 'count-input';

const classMap = utils.generateClassMap(cmpClass, {
    hidden: 'hidden',
});
