import {createBrowserRouter} from 'react-router-dom';
import ErrorBoundaryLayout from '@/routes/ErrorBoundaryLayout';

import {
    NotFound,
    CartPage,
    CatalogPage,
    ProductPage,
    UserFormPage,
    WelcomePage,
    ProfilePage,
    OrderPage,
    OrderListPage,
    UserSettingsPage,
    ChangePasswordPage,
    InvoiceListPage,
    ServiceOrdersListPage,
    HomePage,
    WikiPage,
    TestError,
    MachineRequestPage,
} from '@/components/export';

const router = createBrowserRouter([
    {
        element: <ErrorBoundaryLayout />,
        children: [
            {path: '*', element: <NotFound />},
            {path: '/', element: <HomePage />},
            {path: '/cart', element: <CartPage step={'cart'} />},
            {path: '/cart/contact', element: <CartPage step={'contact'} />},
            {path: '/cart/summary', element: <CartPage step={'summary'} />},
            {path: '/catalog', element: <CatalogPage />},
            {path: '/change-password', element: <ChangePasswordPage />},
            {path: '/invoices', element: <InvoiceListPage />},
            {path: '/login', element: <UserFormPage type={'login'} />},
            {path: '/order/:id', element: <OrderPage />},
            {path: '/orders', element: <OrderListPage />},
            {path: '/product/:id', element: <ProductPage />},
            {path: '/profile', element: <ProfilePage />},
            {path: '/request', element: <UserFormPage type={'register'} />},
            {path: '/reset-password', element: <UserFormPage type={'forgot'} />},
            {path: '/reset-password/:token', element: <UserFormPage type={'reset'} />},
            {path: '/service-orders', element: <ServiceOrdersListPage />},
            {path: '/user-settings', element: <UserSettingsPage />},
            {path: '/welcome', element: <WelcomePage />},
            {path: '/wiki/:name', element: <WikiPage />},
            {path: '/error', element: <TestError />}, // test error page - trigger test error
            {path: '/machine-request', element: <MachineRequestPage />}, // test error page - trigger test error
        ],
    },
]);

export default router;
