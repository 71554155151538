import utils from 'utils';

export const BigTitle = (props: {
    title: string, // todo?: bad name, maybe 'text'
    dark: boolean,
    className: any,
}) => {
    const data = {
        title: props.title || '\u00A0',
        dark: props.dark,
    };

    return <div className={cmpClass + (data.dark ? (' ' + cmpClass + '--dark') : '') + ' ' + props.className}>
        {data.title}
        <span className={classMap.divider}></span>
    </div>;
};

const cmpClass = 'big-title';
const classMap = utils.generateClassMap(cmpClass, {
    divider: 'divider',
    dark: 'dark',
});
