import {
    CatalogInfo,
    CompanyBanner,
    Footer,
    Header,
    UserForm,
    UserRegister,
} from '@components/export';

export const UserFormPage = (props: {
    type: string
}) => {
    return <>
        <Header/>

        <div className="page-content user-form-page">
            {
                props.type !== 'register' ?
                    <div className="user-form-page-container container">
                        <CompanyBanner/>
                        <UserForm type={props.type}/>
                    </div> :
                    <div className="user-form-page-register container">
                        <UserRegister/>
                    </div>
            }
        </div>

        <div className="user-form-page-info container">
            <CatalogInfo />
        </div>

        <Footer/>
    </>;
};
