import utils from 'utils';

export const InvoiceBadge = (props: {
    state?: string,
    color?: string
}) => {
    // fixme: this is duplicate of OrderBadge - good candidate for UiStatusBadge

    return <div className={cmpClass}>
        {
            props.state !== undefined ?
                <div className={props.color + ' ' + classMap.badge}>
                    <div className={classMap.iconStock}><i className='iconfas-circle'></i></div>
                    <span>{props.state}</span>
                </div> :
                <></>
        }
    </div>;
};

const cmpClass = 'invoice-badge';

const classMap = utils.generateClassMap(cmpClass, {
    iconStock: 'icon-stock',
    badge: 'badge',
});
