/**
 * Generates bem child classes
 *
 * format: blo-ck__ele-ment--modi-fier
 *
 * @param {string} parentClass
 * @param {object} map
 * @returns object
 */
const generateClassMap = (parentClass: string, map: object) => {
    Object.entries(map).map((rule) => {
        const [entry, selector] = rule;
        map[entry] = `${parentClass}__${selector}`;
    });

    return map;
};

export default generateClassMap;
