import {useState, useContext} from 'react';

import {
    CompanyLogo,
    HeaderNavMenu,
    HeaderMainMenu,
    HeaderTop,
    MainMenu,
    ScrollToTop,
    RenderImage,
    UiButton,
} from '@/components/export';
import appContext from '@/appContext.tsx';
import { useLabel } from '@/hooks/useLabel';
import utils from 'utils';

export const Header = () => {
    const {currentUser} = useContext(appContext).userContext
    const [showMainMenu, setShowMainMenu] = useState(false);
    const [showSalesContact, setShowSalesContact] = useState(false);
    const label = useLabel();
    const emptyImg = 'img/empty_user_profile.png';

    const handleShowSalesContact = () => {
        setShowSalesContact(!showSalesContact);
    }

    return <>
        <ScrollToTop />
        <div className="header-wrapper">
            <HeaderTop handleShowSalesContact={handleShowSalesContact} />

            <div className="header-main">
                <div className="header-main__container container">
                    <CompanyLogo />

                    <div className="header-main__nav">
                        <CompanyLogo />

                        {currentUser.id ? <HeaderNavMenu /> : <></>}
                    </div>

                    {
                        currentUser.id ?
                            <HeaderMainMenu
                                onToggleMenu={() => setShowMainMenu(!showMainMenu)}
                                active={showMainMenu}
                            /> :
                            <></>
                    }
                </div>
            </div>
        </div>

        {currentUser?.salesContact && showSalesContact &&
            <div className='header-sales-contact'>
                <div className='header-sales-contact__wrapper container'>
                    <div className='header-sales-contact__box'>
                        <p className='header-sales-contact__text header-sales-contact__text--big'>
                            {label.headerSalesContact}
                            <span className='header-sales-contact__divider'></span>
                        </p>

                        <div className='header-sales-contact__content'>
                            {currentUser.salesContact?.profile_photo ? (
                                <RenderImage
                                    src={utils.getApiImgPath(currentUser.salesContact.profile_photo, 'small')}
                                    alt={label.headerSalesContact}
                                    containerClassName='header-sales-contact__img__wrapper'
                                    className='header-sales-contact__img'
                                />
                            ) : (
                                <div className='header-sales-contact__img__wrapper'>
                                    <img src={emptyImg} alt={label.headerSalesContact} className='header-sales-contact__img' />
                                </div>
                            )}
                            <div className='header-sales-contact__text__wrapper'>
                                <p className='header-sales-contact__text bold'>{currentUser.salesContact.first_name} {currentUser.salesContact.last_name}</p>
                                <a href={`mailto:${currentUser.salesContact.email}`} className='header-sales-contact__text link'><span className='iconfa-email icon'/>{currentUser.salesContact.email}</a>
                                <a href={`tel:${currentUser.salesContact.phone_mobile}`} className='header-sales-contact__text link'><span className='iconfa-phone icon'/>{currentUser.salesContact.phone_mobile}</a>
                            </div>
                            <div className='header-sales-contact__buttons'>
                                <UiButton
                                    style={1}
                                    text={label.headerSalesContactButton}
                                    onClick={() => window.open(`mailto:${currentUser.salesContact.email}`)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {
            showMainMenu ?
                <MainMenu onClick={() => setShowMainMenu(false)} /> :
                <></>
        }
    </>;
};
