import utils from 'utils';

import {useNavigate} from 'react-router-dom';
import {useEffect, useContext} from 'react';
// import toast from 'react-hot-toast';

import {BigTitle, ProductCard, ProductsGridSort, UiBadge, UiButton} from '@components/export';
import {useLabel} from '@/hooks/export';

import {Skeleton} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import appContext from '@/appContext';

export const ProductsGrid = (props: {
    limit: number,
    isDemo: boolean,
    mode: 'catalog' | 'recommended' | 'alternative',
    alternativeTo?: string,
    purpose?: string,
    useFilters?: boolean,
}) => {
    const label = useLabel();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const {filters, setFilters, searchRes, searchResQuery} = useContext(appContext).productsContext;

    const productsCount = Array.isArray(searchRes?.pages) && searchRes.pages.at(-1).products.length;

    const flags = {
        noProducts: !searchRes?.pages[0].products.length,
        showMore: productsCount >= props.limit,
        isScrollable:
            props.mode === 'alternative' &&
            (isDesktop ? productsCount > 4 : productsCount > 2),
    };

    const handle = {
        clickLoadMore: () => {
            return props.isDemo ?
                navigate('/catalog') :
                setFilters({
                    ...filters,
                    offset: filters.offset + filters.limit,
                });
        },
        clearFilters() {
            setFilters({
                ...filters,
                attributes: [],
            });
        },
        selectFilter: (name: string, value: string) => {
            setFilters({
                ...filters,
                offset: 0,
                attributes: filters.attributes.filter((item) => {
                    return item.name !== name || item.value !== value;
                }),
            });
        },
    };

    useEffect(() => {
        if (props.purpose) {
            setFilters({
                offset: 0,
                limit: props.limit,
                recommended: props.mode === 'recommended',
                alternativeTo: props.mode === 'alternative' ? props.alternativeTo : '',
                purpose: props.purpose,
                attributes: [],
                useFilters: !!props.useFilters,
            });
        } else {
            setFilters({
                offset: 0,
                limit: props.limit,
                recommended: props.mode === 'recommended',
                alternativeTo: props.mode === 'alternative' ? props.alternativeTo : '',
                attributes: [],
                useFilters: !!props.useFilters,
            });
        }
    }, []);

    useEffect(() => {
        if (props.alternativeTo && props.alternativeTo !== filters.alternativeTo) {
            setFilters({
                offset: 0,
                limit: props.limit,
                recommended: props.mode === 'recommended',
                alternativeTo: props.alternativeTo,
                attributes: [],
            });
        }
    }, [props.alternativeTo]);

    // const toastId = 'catalog-refresh';

    if (props.limit > 11 && searchResQuery && searchResQuery.isFetching) {
        // fixme: bad setState error

        // toast.loading(label.loading, {
        //     id: toastId,
        // });
    } else {
        // toast.dismiss(toastId);
    }

    return <div className={cmpClass}>
        {
            props.mode === 'alternative' && !flags.noProducts ?
                <BigTitle title={label.alternativeProducts} dark={true} className={'product-page-products__title'}/> :
                <></>
        }
        {
            props.mode === 'catalog' ?
                <div className={classMap.controlBar}>
                    <div className={classMap.box}>
                        <span className={classMap.title}>
                            {label.searchResults}
                            <div className={classMap.selectedFilters}>
                                {
                                    filters.attributes !== undefined && filters.attributes.length ?

                                        filters.attributes.map((item, index) => {
                                            return <UiBadge
                                                title={item.label + ': ' + item.value}
                                                onClick={() => handle.selectFilter(item.name, item.value)}
                                                key={index}
                                            />;
                                        }) :
                                        <></>
                                }
                            </div>
                        </span>
                        {
                            filters.attributes !== undefined && filters.attributes.length ?
                                <span
                                    onClick={() => handle.clearFilters()}
                                    className={classMap.clearFilters}
                                >
                                    <><i className={'iconfa-close'}></i>{label.cancelFilters}</>
                                </span> :
                                <></>
                        }
                    </div>
                    <ProductsGridSort/>
                </div> :
                <></>
        }
        {
            props.mode !== 'alternative' || !flags.noProducts ?
                <>
                    <div className={classMap.wrapper + (flags.isScrollable ? ' ' + classMap.wrapperScrollable : '')}>
                        {
                            searchRes === undefined ?
                                [...Array(props.limit)].map((_e, i) => <Skeleton key={i} height={'100%'} variant={'rectangular'}/>) :
                                !flags.noProducts ?
                                    searchRes.pages.map((page, pageKey) => {
                                        return page.products
                                            .slice(0, props.limit)
                                            .map((product, key) => <ProductCard product={product} key={pageKey + key}/>);
                                    }) :
                                    <p>{label.noResults}</p>
                        }
                    </div>
                    {
                        flags.noProducts || !flags.showMore || props.mode === 'alternative' ?
                            <></> :
                            <div className={classMap.loadMore}>
                                <UiButton
                                    style={1}
                                    text={label.showMore}
                                    onClick={() => handle.clickLoadMore()}
                                    loading={searchResQuery.isFetching}
                                />
                            </div>
                    }
                </> :
                <></>
        }
    </div>;
};

const cmpClass = 'products-grid';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    clearFilters: 'clear-filters',
    controlBar: 'control-bar',
    loadMore: 'load-more',
    sort: 'sort',
    title: 'title',
    wrapper: 'wrapper',
    wrapperScrollable: 'wrapper--scrollable',
    selectedFilters: 'selected-filters',
});
