import utils from 'utils';

export const FooterCopyright = () => {
    // todo: move to static config, year dynamic
    const data = {
        copyright: 'Copyright © 2024 ČEMAT trading spol. s r.o.',
        designedBy: 'Designed by Acmark s.r.o.',
    };

    return <div className={cmpClass}>
        <div className={classMap.container}>
            <span>{data.copyright}</span>
            <span>{data.designedBy}</span>
        </div>
    </div>;
};

const cmpClass = 'footer-copyright';
const classMap = utils.generateClassMap(cmpClass, {
    container: 'container container',
});
