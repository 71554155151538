import {useState} from 'react';
import {useApiQuery} from '@/api';

export const useCurrentUser = () => {
    const [currentUser, setCurrentUser] = useState<apiCurrentUser>({});
    const response: apiResponse | void = useApiQuery('getCurrentUser', {})?.data;

    const currentPath = window.location.pathname.split('/')[1];
    const publicPath = ['login', 'request', 'reset-password', 'wiki'];
    const whilteList = ['wiki'];

    if (currentUser.id && publicPath.includes(currentPath) && !whilteList.includes(currentPath)) {
        window.location.pathname = '/welcome';
    }

    if (checkApiResponse(response)) {
        if (response.code === 200) {
            if (!currentUser.id) {
                setCurrentUser(response.data.user);
            }
        } else {
            if (!publicPath.includes(currentPath)) {
                if (currentUser.id) {
                    setCurrentUser({id: ''});
                }

                if (!whilteList.includes(currentPath)) {
                    window.location.pathname = '/login';
                }
            }
        }
    }

    return {
        currentUser,
        setCurrentUser,
    };
};

/**
 * is api response correct
 *
 */
const checkApiResponse = (response: apiResponse | void): response is apiResponse => {
    return (response as apiResponse) !== undefined;
};
