import utils from 'utils';

import {InvoiceBadge} from '@components/export';

import Tooltip from '@mui/joy/Tooltip';

export const InvoiceListItem = (props: {
    handle: any,
    label: any,
    invoice: any,
}) => {
    const {handle, label, invoice} = props;

    const formattedPrice = {
        price: utils.formatPrice(invoice.price),
    };

    return (
        <div className={cmpClass}>
            <div className={classMap.row}>
                <div className={classMap.box}>
                    <div className={classMap.title}>{invoice.name}</div>
                </div>

                <div className={`${classMap.box} ${classMap.boxBadge}`}>
                    <InvoiceBadge state={invoice.status} color={invoice.statusColor} />
                </div>

                <div className={classMap.box}>
                    <div className={classMap.created}>
                        {label.orderCreatedDate + ': ' + utils.getDate(invoice.dateCreated, false)}
                    </div>
                </div>

                <div className={classMap.box}>
                    <div className={classMap.price}>
                        <span className={classMap.priceCurrent}>
                            {formattedPrice.price + ' ' + label.currency}
                        </span>
                    </div>
                </div>

                <div className={classMap.box}>
                    <Tooltip title={label.doPrint} variant="soft" arrow>
                        <div
                            className={classMap.orderAgainBtn + ' iconfas-print'}
                            onClick={() => {
                                handle.invoicePrint(invoice.id);
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>);
};

const cmpClass = 'invoice-list-item';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    title: 'title',
    price: 'price',
    product: 'product',
    priceCurrent: 'price-current',
    row: 'row',
    created: 'created',
    createdBy: 'created-by',
    orderNum: 'order-num',
    boxBadge: 'box-badge',
    orderAgainBtn: 'order-again-btn',
});
