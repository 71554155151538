import utils from 'utils';

import {useState, useMemo} from 'react';

import {useLabel} from '@/hooks/export';
import {UiCheckbox} from '@components/export';

export const FilterListItem = (props: {
    filter: any,
    handle: any,
}) => {
    const label = useLabel();

    const defaultLimit = 6;
    const maxLimit = 100;
    const [displayLimit, setDisplayLimit] = useState(defaultLimit);

    // fixme: set deps for correct visibility
    const flags = {
        initMany: useMemo(() => props.filter.list.length > defaultLimit, []),
    };

    const handle = {
        toggleShowMore() {
            setDisplayLimit(displayLimit === defaultLimit ? maxLimit : defaultLimit);
        },
        searchFilter(value: string, name: string) {
            props.handle.searchFilter(value, name);

            setDisplayLimit(defaultLimit);
        },
    };

    return <div className={classMap.item}>
        <div className={classMap.title}>
            {props.filter.label}
        </div>
        {
            flags.initMany ?
                <div className={classMap.search}>
                    <i className={classMap.iconSearch}></i>
                    <input
                        type="text"
                        placeholder={label.doSearch + '...'}
                        onChange={(e) => handle.searchFilter(e.target.value, props.filter.name)}
                    />
                </div> :
                <></>
        }
        <div className={classMap.content}>
            {
                props.filter.list.map((value: any, index: number) => {
                    if (index < displayLimit) {
                        return <div className={classMap.option} key={index}>
                            <UiCheckbox
                                onChange={
                                    (checked) => props.handle.selectFilter(checked, props.filter.name, value.name, props.filter.label)
                                }
                                disabled={!value.qty && !value.checked}
                                label={value.label + ' ' + `(${value.qty})`}
                                checked={value.checked}
                            />
                        </div>;
                    }
                })
            }
        </div>

        {
            props.filter.list.length > defaultLimit ?
                <div
                    onClick={() => handle.toggleShowMore()}
                    className={classMap.moreOption}
                >
                    {
                        displayLimit > defaultLimit ?
                            <span><i className={'iconfa-arrowUp'}></i> {label.showLess}</span> :
                            <span><i className={'iconfa-arrowDown'}></i> {label.showMore}</span>
                    }
                </div> :
                <></>
        }
    </div>;
};

const cmpClass = 'filter-list-item';
const classMap = utils.generateClassMap(cmpClass, {
    item: 'item',
    title: 'title',
    content: 'content',
    option: 'option',
    moreOption: 'more-option',
    input: 'input',
    search: 'search',
    iconSearch: 'icon-search iconfa-search',
});
