import utils from 'utils';

import {useEffect, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import toast from 'react-hot-toast';

import {TextField} from '@mui/material';

import {Footer, Header, BigTitle, UiButton} from '@components/export';
import {useLabel} from '@/hooks/export';

import appContext from '@/appContext.tsx';

export const UserSettingsPage = () => {
    const label = useLabel();
    const {currentUser} = useContext(appContext).userContext;
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [userData, setUserData] = useState({
        firstName: currentUser?.firstName ?? '',
        lastName: currentUser?.lastName ?? '',
        email: currentUser?.userName ?? '',
    });

    useEffect(() => {
        setUserData({
            firstName: currentUser?.firstName ?? '',
            lastName: currentUser?.lastName ?? '',
            email: currentUser?.userName ?? '',
        });
    }, [currentUser]);

    /**
     * handleSave
     */
    const handleSave = async () => {
        toast.loading(label.saving);
        setSaving(true);

        await utils.post('web/user/update', userData)
            .then((res) => {
                toast.remove();
                setSaving(false);
                if (res.status === true) {
                    toast.success(label.saved);
                    location.reload();
                } else {
                    console.error('handleSave: ', res);
                    toast.error(label.saveError + ': ' + (label[res.message] ?? res.message));
                }
            })
            .catch((err) => {
                toast.remove();
                console.error('handleSave: ', err);
                toast.error(label.saveError + ': ' + (label[err.message] ?? err.message));
            });
    };

    return <>
        <Header />

        <div className={'page-content user-settings-page'}>
            <div className={cmpClass + ' container'}>
                <div className={classMap.head}>
                    <span className={classMap.bread}>
                        <span>{label.profile + ' > '}</span>
                        <span>{label.userSettings}</span>
                    </span>
                    <div className={classMap.titleBox}>
                        <BigTitle title={label.userSettings} dark={true} className={classMap.bigTitle} />
                    </div>
                </div>

                <div className={classMap.card}>
                    <div className={classMap.form}>
                        <TextField
                            label={label.firstName}
                            size={'small'}
                            fullWidth
                            value={userData.firstName}
                            onChange={(e) => setUserData({
                                ...userData,
                                firstName: e.target.value,
                            })}
                        />
                        <TextField
                            label={label.lastName}
                            size={'small'}
                            fullWidth
                            value={userData.lastName}
                            onChange={(e) => setUserData({
                                ...userData,
                                lastName: e.target.value,
                            })}
                        />
                        <TextField
                            label={label.email}
                            size={'small'}
                            fullWidth
                            value={userData.email}
                            onChange={(e) => setUserData({
                                ...userData,
                                email: e.target.value,
                            })}
                            disabled={true}
                        />
                    </div>
                    <div className={classMap.formButtons}>
                        <UiButton
                            style={2}
                            text={label.back}
                            onClick={() => navigate('/profile')}
                        />
                        <UiButton
                            style={1}
                            text={label.save}
                            loading={saving}
                            onClick={() => {
                                handleSave().then(() => {
                                    queryClient.invalidateQueries({queryKey: ['user']});
                                });
                            }}
                            disabled={saving}
                        />
                    </div>
                </div>
            </div>

        </div>

        <Footer />
    </>;
};

const cmpClass = 'user-settings';

const classMap = utils.generateClassMap(cmpClass, {
    bigTitle: 'big-title',
    card: 'card',
    head: 'head',
    list: 'list',
    listItem: 'list-item',
    listTitle: 'list-title',
    bread: 'bread',
    form: 'form',
    formButtons: 'form-buttons',
});
