import {useEffect} from 'react';

import {useApiInfiniteQuery} from '@/api';

export const useProductsSearch = (props?: {
    filters: {
        limit?: number,
        offset?: number,
        machineType?: string,
        attributes?: Array<{name: string, value: string}>,
        recommended?: boolean,
        sort?: string,
        // todo: define type
    },
}) => {
    const query = useApiInfiniteQuery('getProducts', {
        filters: props.filters,
    });

    useEffect(() => {
        if (props.filters.offset) {
            query.fetchNextPage();
        }
    }, [props.filters.offset]);

    return {
        searchRes: query?.data,
        searchResQuery: query,
    };
};
