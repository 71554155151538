import utils from 'utils';

import {useLabel} from '@/hooks/export';
import {UiWeekPicker} from '@components/export';

import {TextField} from '@mui/material';

export const CartListItemOptions = (props: {
    options: additionalInfo,
    setOptions: any, // fixme
    hide?: boolean,
    hideDate?: boolean,
}) => {
    const label = useLabel();

    const {options, setOptions} = props;

    return <>
        <div className={cmpClass}>
            <div className={classMap.actions}></div>
            <div className={classMap.rows}>
                {
                    !props.hide ?
                        <>
                            <div className={classMap.row}>
                                <TextField
                                    label={label.manufacturerNum}
                                    size={'small'}
                                    fullWidth
                                    value={options.manufacturerNum}
                                    onChange={(e) => setOptions({
                                        ...options,
                                        manufacturerNum: e.target.value,
                                    })}
                                />
                                <TextField
                                    label={label.internalNum}
                                    size={'small'}
                                    fullWidth
                                    value={options.internalNum}
                                    onChange={(e) => setOptions({
                                        ...options,
                                        internalNum: e.target.value,
                                    })}
                                />
                            </div>
                            <div className={classMap.row}>
                                <TextField
                                    label={label.note}
                                    fullWidth
                                    multiline
                                    value={options.note}
                                    onChange={(e) => setOptions({
                                        ...options,
                                        note: e.target.value,
                                    })}
                                />
                                {
                                    !props.hideDate ?
                                        <UiWeekPicker
                                            label={label.installDate}
                                            onChange={(value) => setOptions({
                                                ...options,
                                                execDate: value,
                                            })}
                                            value={options.execDate}
                                        /> :
                                        <></>
                                }
                            </div>
                        </> :
                        <></>
                }
            </div>
        </div>
    </>;
};

const cmpClass = 'cart-list-item-options';

const classMap = utils.generateClassMap(cmpClass, {
    actions: 'actions',
    action: 'action',
    row: 'row',
    rows: 'rows',
    datePicker: 'date-picker',
});
