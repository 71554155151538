import {useNavigate} from 'react-router-dom';
import {Skeleton} from '@mui/material';
import {BigTitle, OrderBadge, OrderLineListItem} from '@components/export';
import {useApiQuery} from '@/api';
import {useLabel} from '@/hooks/export';
import utils from 'utils';

export const OrderDetail = (props: {
    id: string, // todo: create Product type
}) => {
    const navigate = useNavigate();
    const label = useLabel();

    const order = useApiQuery('getOrder', {
        id: props.id,
    }).data;

    const handle = {
        navigateToProduct(id: string) {
            if (!id) {
                return;
            }
            navigate(`/product/${id}`);
        },
    };

    // fixme: product img bad ui while loading - empty image instead of nothing

    if (!checkOrder(order)) {
        return <Skeleton />;
    }

    return <div className={cmpClass}>
        <div className={classMap.head}>
            <span className={classMap.bread}>
                <span>{label.orders + ' > '}</span>
                <span>{order.name}</span>
            </span>
            <div className={classMap.titleBox}>
                <OrderBadge state={order.status} color={order.statusColor} />
                <div className={classMap.orderNum}>
                    ({order.orderNum ?? label.orderCreatePending})
                </div>
                <BigTitle title={order.name} dark={true} className={classMap.title} />
            </div>
        </div>
        <div className={classMap.card}>
            <div className={classMap.detail}>
                <div className={classMap.infoLine}>

                    <div className={classMap.info}>
                        <span className={classMap.lbl}>{label.orderAccount + ': '}</span>{(order.orderAccount ?? label.unknown)}
                    </div>
                    <div className={classMap.info}>
                        <span className={classMap.lbl}>{label.orderBranch + ': '}</span>{(order.orderBranch ?? label.unknown)}
                    </div>

                    <div className={classMap.info}>
                        <span className={classMap.lbl}>{label.shippingAccount + ': '}</span>{(order.shippingAccount ?? label.unknown)}
                    </div>
                    <div className={classMap.info}>
                        <span className={classMap.lbl}>{label.shippingBranch + ': '}</span>{(order.shippingBranch ?? label.unknown)}
                    </div>

                    <div className={classMap.info}>
                        <span className={classMap.lbl}>{label.orderContact + ': '}</span>{(order.orderContact ?? label.unknown)}
                    </div>
                    <div className={classMap.info}>
                        <span className={classMap.lbl}>{label.orderCreatedDate + ': '}</span>{utils.getDate(order.dateCreated, false)}
                    </div>

                    <div className={classMap.info}>
                        <span className={classMap.lbl}>{label.orderEnteredDate + ': '}</span>{utils.getDate(order.date_entered, false)}
                    </div>
                    <div className={classMap.info}>
                        <span className={classMap.lbl}>{label.orderCreatedBy + ': '}</span>{(order.cratedBy ?? label.unknown)}
                    </div>

                </div>
            </div>
        </div>
        <div className={classMap.orderLines}>
            {!order.lines || !Array.isArray(order.lines) ?
                <Skeleton
                    height={'100%'}
                    variant={'rectangular'}
                    className={'cart-list-item'}
                /> :
                order.lines.length ?
                    order.lines.map((line) => {
                        return (
                            <OrderLineListItem
                                key={line.id}
                                line={line}
                                label={label}
                                handle={handle}
                            />
                        );
                    }) :
                    <p>{label.noProducts}</p>}
        </div>

        <div className={classMap.pricesBox}>
            <BigTitle title={label.total} dark={true} className={classMap.title}/>
            <div className={classMap.prices}>
                <div className={classMap.price}>
                    <span>{label.noVat}</span>
                    <p>
                        {utils.formatPrice(order.priceNoVat)} {label.currency}
                    </p>
                </div>
                <div className={classMap.price}>
                    <span>{label.withVat}</span>
                    <p>
                        {utils.formatPrice(order.price)} {label.currency}
                    </p>
                </div>
            </div>
        </div>
    </div>;
};

/**
 * is order var valid
 * @param {object} order
 * @returns {boolean}
 */
const checkOrder = (order: apiOrder | void): order is apiOrder => {
    return ![undefined].includes(order as apiOrder);
};

const cmpClass = 'order-detail';

const classMap = utils.generateClassMap(cmpClass, {
    action: 'action',
    actionRow: 'action-row',
    bread: 'bread',
    card: 'card',
    count: 'count',
    description: 'description',
    detail: 'detail',
    head: 'head',
    images: 'images',
    offer: 'offer',
    offerRow: 'offer-row',
    portrait: 'portrait',
    price: 'price',
    priceCurrent: 'price-current',
    priceEmpty: 'price-empty',
    pricePrev: 'price-prev',
    selected: 'selected',
    selector: 'selector',
    sale: 'sale',

    title: 'title',
    titleBox: 'title-box',
    orderLines: 'order-lines',
    orderNum: 'order-num',
    infoLine: 'info-line',
    info: 'info',
    lbl: 'lbl',
    prices: 'prices',
    pricesBox: 'prices-box',
});
