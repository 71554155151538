import utils from 'utils';

import {useState, useEffect} from 'react';

import {CartListItemOptions, CountInput, ProductBadge} from '@components/export';

export const CartListItem = (props: {
    handle: any,
    label: any,
    product: any,
    readonly?: boolean,
}) => {
    const {handle, label, product} = props;

    const [additionalInfo, setAdditionalInfo] = useState(product.additionalInfo);
    const [showOptions, setShowOptions] = useState(false);

    const noPriceLabel = '--,-';

    const priceByQty = product.price.current * product.qty;

    const formattedPrice = {
        current: product.price.current ? utils.formatPrice(product.price.current) : noPriceLabel,
        prev: product.price.prev ? utils.formatPrice(product.price.prev) : noPriceLabel,
        byQty: priceByQty ? utils.formatPrice(priceByQty) : noPriceLabel,
    };

    const flags = {
        needsExec:
            !!product?.additionalServices && Object.entries(product.additionalServices)
                .filter((entry: any) => entry[1].qty > 0)
                .length,
    };

    useEffect(() => {
        handle.updateItem(
            product.id,
            product.qty,
            product.additionalServices,
            additionalInfo,
        );
    }, [additionalInfo]);

    return <div className={cmpClass}>
        <div className={classMap.row}>
            <div className={classMap.box}>
                <div className={classMap.img}><img src={product.img} alt=""/></div>
                <div className={classMap.product}>
                    <div className={classMap.title} onClick={() => handle.navigateToProduct(product.id)}>{product.title}</div>
                    <div className={classMap.info}>
                        <span className={classMap.code}>
                            {label.productCodeShort + ': ' + product.code}
                        </span>
                        <ProductBadge stock={product.info.stock} isDiscount={product.info.isDiscount} />
                        {
                            product.message ?
                                <p className={classMap.message}>{product.message}</p> :
                                <></>
                        }
                    </div>
                </div>
            </div>
            <div className={classMap.box + (props.readonly ? ' readonly' : '')}>
                <div className={classMap.price}>
                    <span className={classMap.priceCurrent}>
                        <span>
                            {
                                (product.qty <= 1 ? formattedPrice.current : formattedPrice.byQty) + ' ' + label.currency
                            }
                        </span>
                        {
                            product.qty > 1 ?
                                <span className={'secondary'}>
                                    {'(' + formattedPrice.current + ' ' + label.currency + ' x ' + product.qty + label.qty + ')'}
                                </span> :
                                <></>
                        }
                    </span>
                </div>
                <div className={classMap.action}>
                    {
                        !product.hideQty ?
                            <CountInput
                                initCount={product.qty}
                                onChange={(currentCount) => {
                                    // fixme: temp fix - set all service qty same as product
                                    const tempServices: any = {};

                                    for (const prop in product.additionalServices) {
                                        tempServices[prop] = {
                                            ...product.additionalServices[prop],
                                            qty: product.additionalServices[prop].qty ? currentCount : 0,
                                        };
                                    }

                                    handle.updateItem(
                                        product.id,
                                        currentCount,
                                        tempServices,
                                    );
                                }}
                                max={product.info.stock}
                                readonly={props.readonly}
                            /> : <></>
                    }
                    <div className={classMap.actionBtns}>
                        {
                            !props.readonly ?
                                <button onClick={() => handle.dropItem(product.id)}>
                                    <i className={classMap.iconTrash}></i>
                                    <span> {label.drop}</span>
                                </button> :
                                <></>
                        }
                        <button onClick={() => setShowOptions(!showOptions)}>
                            <>
                                <i className={showOptions ? 'iconfas-edit' : 'iconfa-edit'}></i>
                                <span> {label.specify}</span>
                            </>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className={classMap.row}>
            {
                showOptions ?
                    <CartListItemOptions
                        options={additionalInfo}
                        setOptions={setAdditionalInfo}
                        hideDate={!flags.needsExec}
                        hide={!showOptions}
                    /> :
                    <></>
            }
        </div>
    </div>;
};

const cmpClass = 'cart-list-item';
const classMap = utils.generateClassMap(cmpClass, {
    action: 'action',
    actionBtns: 'action-btns',
    box: 'box',
    code: 'code',
    img: 'img',
    title: 'title',
    info: 'info',
    iconTrash: 'icon-trash iconfa-trash',
    message: 'message',
    price: 'price',
    product: 'product',
    priceCurrent: 'price-current',
    pricePrev: 'price-prev',
    row: 'row',
});
