import ReactDOM from 'react-dom/client';
import {RouterProvider} from 'react-router-dom';

import App from '@/App.tsx';
import router from '@/routes/routes';

import '@/../sass/index.scss';

ReactDOM
    .createRoot(document.getElementById('root')!) // todo:? remove - more in console
    .render(
        <App>
            <RouterProvider router={router}/>
        </App>,
    );
