import utils from 'utils';

import {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useLabel} from '@/hooks/export';
import Pagination from '@mui/material/Pagination';

import {useApiQuery} from '@/api';

import {Footer, Header, OrdersList, BigTitle} from '@components/export';

export const OrderListPage = () => {
    const label = useLabel();

    const [searchParams, setSearchParams] = useSearchParams();
    const urlPage: number = isNaN(Number(searchParams.get('page'))) ? 0 : Number(searchParams.get('page'));

    const limit = 10;

    const [page, setPage] = useState<number>(urlPage ? (urlPage - 1) : 0);

    // get count countOrders
    // @ts-ignore fixme
    const countOrders: number = useApiQuery('countOrders', {}).data?.count;

    const pagesTotal = Math.ceil(countOrders / limit);

    /**
     * handleChangePage
     * @param {React.ChangeEvent<unknown>} _event
     * @param {number} value
     */
    const handleChangePage = (_event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
        // change url param page
        searchParams.set('page', String(value));
        setSearchParams(searchParams);
    };

    return <>
        <Header/>

        <div className={'page-content ' + cmpClass}>
            <div className={classMap.container + ' container'}>
                <BigTitle title={label.orders} dark={true} className={''}/>
                <OrdersList
                    limit={limit}
                    offset={limit * page}
                />

                <div className={classMap.pagination}>
                    <Pagination
                        count={pagesTotal}
                        page={page + 1}
                        onChange={handleChangePage}
                    />
                </div>
            </div>

        </div>

        <Footer/>
    </>;
};

const cmpClass = 'order-list-page';
const classMap = utils.generateClassMap(cmpClass, {
    container: 'container',
    pagination: 'pagination',
});
