import utils from 'utils';
import {useNavigate} from 'react-router-dom';

import {UiButton} from '@components/export';
import {useLabel} from '@/hooks/export';

export const WelcomeBanner = () => {
    const navigate = useNavigate();
    const label = useLabel();

    const data = {
        textBig: 'Pneu skladem u nás - vyberte si',
        textSmall: 'Poptejte rozměr nebo si ho v konfigurátoru najděte dle typu stroje',
    };

    return <div className={cmpClass}>
        <div className={classMap.container}>
            <div className={classMap.box}>
                <p className={`${classMap.text} ${classMap.textBig}`}>
                    {data.textBig}
                    <span className={classMap.divider}></span>
                </p>
                <span className={`${classMap.text} ${classMap.textSmall}`}>
                    {data.textSmall}
                </span>
                <UiButton
                    onClick={() => navigate('/catalog')}
                    text={label.showCatalog}
                    style={2}
                />
            </div>
        </div>
    </div>;
};

const cmpClass = 'welcome-banner';
const classMap = utils.generateClassMap(cmpClass, {
    container: 'container container',
    box: 'box',
    text: 'text',
    textBig: 'text--big',
    textSmall: 'text--small',
    menuLink: 'menu-link',
    divider: 'divider',
});
