import {useState} from 'react';
import Skeleton from '@mui/material/Skeleton';
import utils from 'utils';

interface RenderImageProps {
    src: string;
    alt?: string;
    className?: string;
    containerClassName?: string;
    onClick?: () => void;
    [key: string]: any;
}

export function RenderImage({src, alt, className, containerClassName, onClick, ...props}: RenderImageProps) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isValidSrc, setIsValidSrc] = useState(!!src);

    return (
        <div className={`${cmpClass} ${containerClassName}`}>
            <div className={classMap.contentWrapper} onClick={onClick}>
                {isValidSrc ? (
                    <img
                        src={src}
                        alt={alt}
                        className={`${classMap.image} ${imageLoaded ? classMap.visible : classMap.hidden} ${className}`}
                        onError={() => setIsValidSrc(false)}
                        onLoad={()=> setImageLoaded(true)}
                        {...props}
                    />

                ) : (
                    <div className={classMap.error} {...props}>
                        <span className='iconfas-imageError'/><br/>
                        <p>{alt}</p>
                    </div>
                )}
                {isValidSrc && (
                    <div className={`${classMap.placeholder} ${!imageLoaded ? classMap.visible : classMap.hidden}`}>
                        <Skeleton variant="rectangular" className={classMap.loader} width='100%' height='100%' />
                    </div>
                )}
            </div>
        </div>
    );
}

const cmpClass = 'image-render-wrapper';
const classMap = utils.generateClassMap(cmpClass, {
    image: 'image',
    visible: 'visible',
    hidden: 'hidden',
    placeholder: 'placeholder',
    loader: 'loader',
    error: 'error',
    contentWrapper: 'content-wrapper',
});
