import utils from 'utils';
import {useApiQuery} from '@/api';

import {useState, useContext, useEffect} from 'react';
import toast from 'react-hot-toast';
import {useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';

import {BigTitle, CountInput, UiButton, ProductBadge, RenderImage} from '@components/export';
import {useLabel} from '@/hooks/export';

import {Skeleton, useMediaQuery} from '@mui/material';

import appContext from '@/appContext';

import emptyImg from '@img/test1.jpg';

// todo: eslint
export const ProductDetail = (props: {
    id: string, // todo: create Product type
}) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const label = useLabel();
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const {currentUser} = useContext(appContext).userContext;
    const cart = useContext(appContext).cartContext;
    const product: apiProduct | void = useApiQuery('getProduct', {
        id: props.id,
    }).data;

    const [qty, setQty] = useState(1);

    const data = {
        categoryName: '',
        description: {__html: ''},
        img: [],
        isDiscount: false,
        name: '',
        priceCurrent: 0.00,
        pricePrev: 0.00,
        stock: 0,
        additionalServices: [],
    };

    const handle = {
        imgSelect: (_index: number) => {
            // console.log('imgSelect:36', index); // todo: remove!
        },
        addToCart: () => {
            Object.entries(data.additionalServices).map((item) => {
                data.additionalServices[item[0]].qty = 0;
            });

            utils.logEvent('cartEntry', {
                productId: props.id,
                qty: qty,
            });

            cart.updateItem(props.id, qty, data.additionalServices)
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['cart']});

                    toast.remove();
                    toast.success(label.cartIsUpToDate);
                });
        },
        notFound: () => {
            navigate('/catalog');
        },
    };

    if (product !== undefined && checkProduct(product)) {
        if (product === null) {
            handle.notFound();
        } else {
            data.categoryName = product.categoryName;
            data.description = {__html: product.description};
            data.img = product.images;
            data.isDiscount = product.isDiscount;
            data.name = product.name;
            data.priceCurrent = product.priceCurrent;
            data.pricePrev = product.pricePrev;
            data.stock = product.stock;
            data.additionalServices = product.additionalServices;
        }
    }

    const flags = {
        showPrevPrice: data.isDiscount || data.priceCurrent !== data.pricePrev,
        isLoading: !data.name,
    };

    useEffect(() => {
        // save product id to lastSeenProducts array local storage - limit array length to 10 items
        const lastSeenProducts = JSON.parse(localStorage.getItem('lastSeenProducts') || '{}');
        const userId = currentUser.id;

        if (!userId) {
            return;
        }

        if (lastSeenProducts[userId] === undefined) {
            lastSeenProducts[userId] = [];
        }

        if (!lastSeenProducts[userId].includes(props.id)) {
            if (lastSeenProducts[userId].length >= 20) { // store last 20 products in lastSeenProducts local storage
                lastSeenProducts[userId].shift();
            }
            lastSeenProducts[userId].push(props.id);
        } else {
            // move product id to the end of array
            lastSeenProducts[userId].push(lastSeenProducts[userId].splice(lastSeenProducts[userId].indexOf(props.id), 1)[0]);
        }

        localStorage.setItem('lastSeenProducts', JSON.stringify(lastSeenProducts));

        // invalidate getProducts query
        queryClient.invalidateQueries({queryKey: ['getProducts']});
    }, [queryClient, props.id, currentUser.id]);

    // fixme: product img bad ui while loading - empty image instead of nothing

    return <div className={cmpClass}>
        <div className={classMap.head}>
            <span className={classMap.bread}>
                <span>{data.categoryName + ' > '}</span>
                <span>{data.name}</span>
            </span>
            <BigTitle title={data.name} dark={true} className={classMap.title} />
        </div>
        <div className={classMap.card}>
            <div className={classMap.images}>
                <div className={classMap.portrait}>
                    <div className={classMap.info}>
                        {
                            !flags.isLoading ?
                                <ProductBadge stock={data.stock} isDiscount={data.isDiscount} /> :
                                <></>
                        }
                    </div>
                    <a href={utils.getApiImgPath(data.img[0], 'preview')} target="_blank" rel="noreferrer">
                        {
                            !flags.isLoading ?
                                // <img src={data.img[0] ? utils.getApiImgPath(data.img[0], 'large') : emptyImg} alt=""/>
                                <RenderImage
                                    src={data.img[0] ? utils.getApiImgPath(data.img[0], 'large') : emptyImg}
                                    className={classMap.imagePreview}
                                    alt=""
                                /> :
                                <Skeleton width='100%' height='100%'/>
                        }
                    </a>
                </div>
                <div className={classMap.selector}>
                    {
                        !flags.isLoading ?
                            data.img.map((src, index) => {
                                // return <img
                                //     src={src ? utils.getApiImgPath(src, 'thumbnail') : emptyImg}
                                //     onClick={() => handle.imgSelect(index)}
                                //     alt={data.name}
                                //     key={src}
                                // />;
                                return <RenderImage
                                    src={src ? utils.getApiImgPath(src, 'thumbnail') : emptyImg}
                                    className={classMap.imageThumbnailPreview}
                                    onClick={() => handle.imgSelect(index)}
                                    alt={data.name}
                                    key={src}
                                />;
                            }) :
                            <Skeleton width='100%' height='100%'/>
                    }
                </div>
            </div>
            <div className={classMap.detail}>
                <div className={classMap.description} dangerouslySetInnerHTML={data.description}></div>
                <div className={classMap.offer}>
                    <div className={classMap.offerRow}>
                        {
                            !isDesktop ?
                                <ProductBadge stock={data.stock} hideDiscount /> :
                                <></>
                        }
                    </div>
                    <div className={classMap.offerRow}>
                        <div className={classMap.price}>
                            {
                                flags.showPrevPrice ?
                                    <span className={classMap.pricePrev}>
                                        {label.pricePrev + ' ' + utils.formatPrice(data.pricePrev) + ' ' + label.currency}
                                    </span> :
                                    <></>
                            }
                            <div className={classMap.priceCurrent}>
                                <span>{label.priceCurrentNoVat}</span>
                                {
                                    data.priceCurrent ?
                                        <p>{utils.formatPrice(data.priceCurrent) + ' ' + label.currency}</p> :
                                        <Skeleton />
                                }
                            </div>
                        </div>
                        <div className={classMap.action}>
                            <div className={classMap.actionRow}>
                                {
                                    isDesktop && !flags.isLoading ?
                                        <ProductBadge stock={data.stock} hideDiscount /> :
                                        <></>
                                }
                            </div>
                            <div className={classMap.actionRow}>
                                <CountInput initCount={data.stock ? 1 : 0} onChange={(counter) => setQty(counter)} max={data.stock} />
                                <UiButton
                                    style={1}
                                    text={label.addToCart}
                                    onClick={() => handle.addToCart()}
                                    disabled={!data.priceCurrent || !data.stock}
                                    loading={flags.isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classMap.description} dangerouslySetInnerHTML={data.description}></div>
            </div>
        </div>
    </div>;
};

/**
 * is product var valid
 * @param product
 */
const checkProduct = (product: apiProduct | void): product is apiProduct => {
    return ![undefined].includes(product as apiProduct);
};

const cmpClass = 'product-detail';
const classMap = utils.generateClassMap(cmpClass, {
    action: 'action',
    actionRow: 'action-row',
    bread: 'bread',
    card: 'card',
    count: 'count',
    description: 'description',
    detail: 'detail',
    head: 'head',
    images: 'images',
    offer: 'offer',
    offerRow: 'offer-row',
    portrait: 'portrait',
    price: 'price',
    priceCurrent: 'price-current',
    priceEmpty: 'price-empty',
    pricePrev: 'price-prev',
    selected: 'selected',
    selector: 'selector',
    sale: 'sale',
    title: 'title',
    info: 'info',
    imagePreview: 'image-preview',
    imageThumbnailPreview: 'image-thumbnail-preview',
});
