const formatPrice = (
    price: number,
): string => {
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return numberWithCommas(Number(price).toFixed(2))
        .replace('.', ',');
};

export default formatPrice;
