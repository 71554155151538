import {useContext} from 'react';

import {UiSelect} from '@components/export';
import {useLabel} from '@/hooks/export';

import appContext from '@/appContext';

export const ProductsGridSort = () => {
    const label = useLabel();

    const {filters, setFilters} = useContext(appContext).productsContext;

    const data = {
        options: [
            {value: '', label: ''},
            {value: 'priceAsc', label: <span><i className={'iconfa-arrowUp'}></i> {label.price}</span>},
            {value: 'priceDesc', label: <span><i className={'iconfa-arrowDown'}></i> {label.price}</span>},
            {value: 'manufacturerAsc', label: <span><i className={'iconfa-arrowUp'}></i> {label.manufacturer}</span>},
            {value: 'manufacturerDesc', label: <span><i className={'iconfa-arrowDown'}></i> {label.manufacturer}</span>},
        ],
    };

    const handle = {
        setSort(value) {
            setFilters({
                ...filters,
                sort: value,
                offset: 0,
            });
        },
    };

    return <div className={cmpClass}>
        <UiSelect
            name={'productSorting'}
            label={label.doSort}
            value={filters['sort'] || ''}
            options={data.options}
            onChange={(value) => {
                handle.setSort(value);
            }}
        />
    </div>;
};

const cmpClass = 'products-grid-sort';
