import utils from 'utils';
import {api} from '@/api';

import {useRef, useState} from 'react';

import {useLabel} from '@/hooks/export';
import {UiButton, UiSelect} from '@components/export.ts';

import {TextField} from '@mui/material';

export const MachineRequestForm = () => {
    const label = useLabel();

    const [message, setMessage] = useState('');
    const [attachments, setAttachments] = useState([]);

    const [upload, setUpload] = useState({
        file: null,
        purpose: '',
    });

    const purposeOptions = [
        {value: 'frontWheel', label: 'Přední kolo'},
        {value: 'backWheel', label: 'Zadní kolo'},
        {value: 'label', label: 'Štítek'},
        {value: 'whole', label: 'Celek'},
    ];

    const uploadInputRef = useRef(null);

    const handle = {
        send() {
            const formData = new FormData();

            attachments.map((attachment, i) => {
                formData.append(`attachments[${i}]`, attachment.file);
            });

            formData.append('message', message);

            api.machine.request(formData)
                .then((response) => {
                    console.log(':38', response); // todo: remove!
                });
        },
        upload() {
            setAttachments([
                ...attachments,
                upload,
            ]);

            setUpload({
                file: null,
                purpose: '',
            });

            uploadInputRef.current.value = '';
        },
    };

    return <div className={cmpClass}>
        <TextField
            className={classMap.message}
            label={label.message}
            size={'small'}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            multiline
            minRows={10}
            maxRows={20}
            fullWidth
            required={true}
            // error={!message}
        />

        <div className={classMap.upload}>

            <div className={classMap.selectImg}>
                <button
                    onClick={() => {
                        uploadInputRef.current.click();
                    }}
                >
                    {
                        upload.file ?
                            upload.file.name :
                            'select image'
                    }
                </button>
                <input
                    type="file"
                    ref={uploadInputRef}
                    onChange={
                        (e) => {
                            // todo: validate img type

                            setUpload({
                                ...upload,
                                file: e.target.files[0],
                            });
                        }
                    }
                />
            </div>
            <UiSelect
                name={'urceni'}
                label={label.purpose}
                value={upload.purpose}
                options={purposeOptions}
                onChange={(value) => {
                    setUpload({
                        ...upload,
                        purpose: value.toString(),
                    });
                }}
            />
            <UiButton style={2} text={'upload'} onClick={() => handle.upload()}/>
        </div>

        <div className={classMap.uploadList}>
            {
                attachments.length ?
                    attachments.map((attachment, index) => {
                        return <div
                            className={classMap.attachment}
                            key={index}
                        >
                            <div className={classMap.image}>
                                <img
                                    src={URL.createObjectURL(attachment.file)}
                                    alt={attachment.file.name}
                                />
                                <span className={classMap.imageInfo}>{formatFileSize(attachment.file.size)}</span>
                            </div>
                            <span>{attachment.file.name}</span>
                            <span>
                                {
                                    attachment.purpose ?
                                        purposeOptions.find((v) => v.value === attachment.purpose).label :
                                        ''
                                }
                            </span>
                            <button
                                onClick={() => setAttachments(
                                    attachments.filter((_, i) => i !== index),
                                )}
                            >
                                <i className={'iconfa-close'}></i>
                            </button>
                        </div>;
                    }) :
                    <span className={classMap.attachment}>
                        {label.noAttachments}
                    </span>
            }
        </div>

        <div className={classMap.submit}>
            <UiButton
                style={1}
                text={'send'}
                onClick={() => handle.send()}
            />
        </div>
    </div>;
};

const cmpClass = 'machine-request-form';
const classMap = utils.generateClassMap(cmpClass, {
    upload: 'upload',
    uploadList: 'upload-list',
    attachment: 'attachment',
    message: 'message',
    image: 'image',
    imageInfo: 'image-info',
    submit: 'submit',
    selectImg: 'select-img',
});

const formatFileSize = (size) => {
    if (size < 1024) {
        return `${size} bytes`;
    } else if (size < 1048576) {
        return `${(size / 1024).toFixed(2)} KB`;
    } else {
        return `${(size / 1048576).toFixed(2)} MB`;
    }
};
