import utils from 'utils';

export const UiButton = (props: {
    style: number,
    text: string | JSX.Element,
    onClick: () => void,
    disabled?: boolean,
    icon?: string,
    loading?: boolean,
}) => {
    const attrs = {};

    if (props.disabled) {
        attrs['disabled'] = true;
    }

    return <button
        className={cmpClass + ' ' + classMap['style' + props.style] + (props.icon ? ' ' + classMap.hasIcon : '')}
        onClick={() => props.onClick()}
        {...attrs}
    >
        {props.icon ? <i className={`${props.icon} ${classMap.icon}`}></i> : ''}
        {
            props.loading ?
                <span className={classMap.hidden}>{props.text}</span> :
                <span>{props.text}</span>
        }
        {
            props.loading ?
                <i className={classMap.iconLoading}></i> :
                <></>
        }
    </button>;
};

const cmpClass = 'ui-button';
const classMap = utils.generateClassMap(cmpClass, {
    style1: 'style-1',
    style2: 'style-2',
    icon: 'icon',
    hidden: 'hidden',
    hasIcon: 'has-icon',
    iconLoading: 'icon-loading iconfas-spinner',
});
