/**
 * Posts data to the rest API
 * @param {string} path
 * @param {any} data
 * @param {any} customHeaders
 * @returns Promise
 */
export default async function post(path: string, data: any, customHeaders: any = {}) {
    // const apiKey = '6VS314WZ+YtFPrYqqV2EZAoxKJNIyr13JGKTWjlOy6jz5zWKr-C8ZnpCJ7ChvZfW';
    const apiUrl = this.config?.api;

    const headers = {
        // 'Api-Key': apiKey,
        'Content-Type': 'application/json',
        ...customHeaders,
    };

    const response = await this.rest.post(apiUrl + path, data, headers);

    // if status is not true, throw error
    // call .error() to show error message
    if (!response.status) {
        // console.error(response.message);
    }

    return response;
}
