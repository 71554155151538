import {useInfiniteQuery} from '@tanstack/react-query';

import {api} from '@/api/index.ts';

export const useApiInfiniteQuery = (
    name: string,
    data: any,
) => {
    const initialPageParam = 0;
    let enabled = false;
    let getNextPageParam = () => 0;
    let queryFn: (key: any) => any;
    let queryKey = [];

    switch (name) {
        case 'getProducts': {
            queryKey = ['products', {
                limit: data.filters.limit,
                name: data.filters.name,
                machineType: data.filters.machineType,
                attributes: data.filters.attributes,
                sort: data.filters.sort,
                purpose: data.filters.purpose,
                alternativeTo: data.filters.alternativeTo,
            }];
            queryFn = () => api.products.getMany(data);
            getNextPageParam = () => data.filters.offset;
            enabled = !!data.filters.limit;
        }
    }

    return useInfiniteQuery({
        queryKey,
        queryFn,
        initialPageParam,
        getNextPageParam,
        enabled,
        gcTime: 0,
    });
};
