import {api, useApiQuery} from '@/api';

import {useState} from 'react';

export const useCart = (props?: {
    enabled?: boolean,
}) => {
    const query = useApiQuery('getCart', {}, !props?.enabled);

    const cart : void | apiCart = query.data;

    const [contactForm, setContactForm] = useState({
        contactEmail: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        doSaveAddress: false,
        doSaveContact: false,
        execCity: '',
        execCompany: '',
        execPostalCode: '',
        execStreet: '',
        info2: '',
        info: 'Fakturační firma podle pobočky uživatele',
        invoiceCity: '',
        invoiceCompany: '',
        invoicePostalCode: '',
        invoiceStreet: '',
    });

    return {
        query,
        cart,
        updateItem,
        updateNote,
        clean: () => api.cart.clean(),
        submit: () => api.cart.submit({
            products: (cart as unknown as apiCart).list, // fixme - temp fix
            contact: {
                email: contactForm.contactEmail,
                firstName: contactForm.contactFirstName,
                lastName: contactForm.contactLastName,
                phone: contactForm.contactPhone,
            },
            invoiceAddress: {
                city: contactForm.invoiceCity,
                company: contactForm.invoiceCompany,
                postalCode: contactForm.invoicePostalCode,
                street: contactForm.invoiceStreet,
            },
            execAddress: {
                city: contactForm.execCity,
                company: contactForm.execCompany,
                postalCode: contactForm.execPostalCode,
                street: contactForm.execStreet,
            },
        }),
        saved: {
            execAddresses: useApiQuery('getSavedAddresses', {}, !props?.enabled).data, // todo: add types
            contacts: useApiQuery('getSavedContacts', {}, !props?.enabled).data,
        },
        contactForm,
        setContactForm,
    };
};

const updateItem = (
    id: string,
    qty: number,
    additionalServices: [additionalService],
    additionalInfo?: {
        manufacturerNum: string,
        internalNum: string,
        note: string,
        execDate: string, // todo: check date format?
    },
) => {
    // todo: clear execDate if services total qty = 0

    return api.cart.update({
        productId: id,
        qty: qty,
        additionalServices: additionalServices,
        additionalInfo: additionalInfo || {},
    });
};

const updateNote = (note: string) => {
    return api.cart.updateNote({
        note,
    });
}
