import {useParams} from 'react-router-dom';

import {
    Footer,
    Header,
    ProductAttrs,
    ProductDetail,
    ProductsGrid,
} from '@components/export';

export const ProductPage = () => {
    const productId = useParams().id;

    // todo: move render data to here -> pass as props

    return <>
        <Header/>

        <div className="page-content product-page">
            <div className="product-page-product container">
                <ProductDetail id={productId}/>
                <ProductAttrs id={productId}/>
            </div>

            <div className="product-page-products container">
                <ProductsGrid limit={10} isDemo={true} mode={'alternative'} alternativeTo={productId}/>
            </div>
        </div>

        <Footer/>
    </>;
};
