import {useState, useEffect, useRef, useCallback} from 'react';
import utils from 'utils';

import {BigTitle} from '@components/export';
import {useLabel} from '@/hooks/export';

import banner1 from '@img/BannerV2_1.jpg';
import banner2 from '@img/BannerV2_2.jpg';
import banner3 from '@img/BannerV2_3.jpg';

export const CompanyBanner = () => {
    const label = useLabel();
    const slidesChangeTime = 3000;
    const [state, setState] = useState({
        active: 0,
        carouselActive: true,
        slides: [
            banner1,
            banner2,
            banner3,
        ],
    });

    const interval = useRef(null);
    let activeIndex = 0;

    /**
     * changeSlide function called from dots and carousel
     * @param {number} index
     */
    const changeSlide = (index) => {
        setState({
            ...state,
            active: index,
            carouselActive: false,
        });
        clearInterval(interval.current);
    };

    /**
     * nextSlide function called from carousel
     */
    const nextSlide = useCallback(() => {
        const nextSlideIndex = activeIndex === state.slides.length - 1 ? 0 : activeIndex + 1;
        setState({
            ...state,
            active: nextSlideIndex,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        activeIndex = nextSlideIndex;
    }, [state, activeIndex]);

    useEffect(() => {
        if (state.carouselActive && state.slides.length > 1 && interval.current === null) {
            interval.current = setInterval(() => {
                nextSlide();
            }, slidesChangeTime);
        }
    }, [state.slides, state.carouselActive, nextSlide]);

    // todo: here will be carousel - controlled?
    return <div className={cmpClass}>
        <div className={classMap.images}>
            {
                state.slides.map((slide, index) => {
                    return (
                        <img
                            src={slide}
                            alt=""
                            className={classMap.img + (state.active === index ? ' ' + classMap.imgSelected : '')}
                            key={'loginCarouselWidget' + index}
                        />

                    );
                })
            }
        </div>
        <div className={classMap.info}>
            <div className={classMap.selector}>
                {
                    state.slides.map((_, index) => {
                        return (
                            <div className={classMap.option + (state.active === index ? ' active' : '')}
                                key={'loginCarouselWidget' + index}
                                onClick={() => {
                                    changeSlide(index);
                                }}></div>
                        );
                    })
                }
            </div>
            <BigTitle title={label.welcomeWish} dark={false} className={classMap.title} />
        </div>
    </div>;
};

const cmpClass = 'company-banner';
const classMap = utils.generateClassMap(cmpClass, {
    images: 'images',
    imgSelected: 'img--selected',
    img: 'img',
    info: 'info',
    selector: 'selector',
    option: 'option',
    optionSelected: 'option--selected',
    title: 'title',
});
