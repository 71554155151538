import utils from 'utils';

import {ServiceOrderBadge} from '@components/export';

import Tooltip from '@mui/joy/Tooltip';

export const ServiceOrderListItem = (props: {
    handle: any,
    label: any,
    invoice: any,
}) => {
    const {handle, label, invoice} = props;

    return (
        <div className={cmpClass}>
            <div className={classMap.row}>
                <div className={classMap.box}>
                    <div className={classMap.title}>{invoice.name}</div>
                </div>

                <div className={`${classMap.box} ${classMap.boxBadge}`}>
                    <ServiceOrderBadge state={invoice.status} color={invoice.statusColor} />
                </div>

                <div className={classMap.box}>
                    <div className={classMap.created}>
                        {label.serviceOrderDate + ': ' + utils.getDate(invoice.date, false)}
                    </div>
                </div>

                <div className={classMap.box}>
                    <div className={classMap.printLabel}>
                        {label.serviceOrderPrint}
                    </div>
               
                    <Tooltip title={label.doPrint} variant="soft" arrow>
                        <div
                            className={classMap.orderAgainBtn + ' iconfas-print'}
                            onClick={() => {
                                handle.invoicePrint(invoice.id);
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>);
};

const cmpClass = 'service-order-list-item';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    title: 'title',
    price: 'price',
    product: 'product',
    priceCurrent: 'price-current',
    row: 'row',
    created: 'created',
    createdBy: 'created-by',
    orderNum: 'order-num',
    boxBadge: 'box-badge',
    orderAgainBtn: 'order-again-btn',
    printLabel: 'print-label',
});
