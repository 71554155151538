import utils from 'utils';

import {useContext} from 'react';

import {useLabel} from '@/hooks/export';

import appContext from '@/appContext';

export const ProductSearch = () => {
    const label = useLabel();

    const {filters, setFilters} = useContext(appContext).productsContext;

    const handle = {
        inputChange: (value: string) => {
            setFilters({
                ...filters,
                name: value,
            });
        },
    };

    return <div className={cmpClass}>
        <input
            onChange={(e) => handle.inputChange(e.target.value)}
            placeholder={label.searchProducts + '...'}
            type="text"
            className={classMap.input}
            autoFocus
        />
        <i className={classMap.iconSearch}></i>
    </div>;
};

const cmpClass = 'product-search';
const classMap = utils.generateClassMap(cmpClass, {
    input: 'input',
    iconSearch: 'icon iconfa-search',
});
