import {useNavigate} from 'react-router-dom';
import {Skeleton} from '@mui/material';
import {OrderListItem} from '@components/export';
import {useLabel} from '@/hooks/export';
import {useApiQuery} from '@/api';

export const OrdersList = (props: {
    limit: number,
    offset: number,
}) => {
    const navigate = useNavigate();
    const label = useLabel();

    const orders = useApiQuery('getOrders', {
        limit: props?.limit ?? 5,
        offset: props?.offset ?? 0,
    }).data;

    const handle = {
        navigateToOrder(id: string) {
            navigate(`/order/${id}`);
        },
        // orderAgain(id: string) {
        //     navigate(`/order-again/${id}`);
        // }
    };

    return <div className={cmpClass}>
        {
            !checkOrders(orders) ?
                <Skeleton
                    height={'100%'}
                    variant={'rectangular'}
                    className={'cart-list-item'}
                /> :
                orders.length ?
                    orders.map((order) => {
                        return (
                            <OrderListItem
                                key={order.id}
                                order={order}
                                label={label}
                                handle={handle}
                            />
                        );
                    }) :
                    <p>{label.noOrders}</p>
        }
    </div>;
};

/**
 * are orders var valid
 * @param {array} orders
 * @returns {boolean}
 */
const checkOrders = (orders: apiOrder[] | void): orders is apiOrder[] => {
    return ![undefined, null].includes(orders as apiOrder[]);
};

const cmpClass = 'orders-list';
