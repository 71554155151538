import {useRef} from 'react';
import {useApiQuery} from '@/api';

export const useMachineFilter = (
    brand: string,
) => {
    const query = useApiQuery('getFiltersMachines', {brand});
    const models = [];

    const currentBrands = useRef([]);

    if (query?.data !== undefined && Array.isArray(query.data)) {
        if (!currentBrands.current.length) {
            query.data.map((item) => {
                currentBrands.current.push(item.brand);
            });
        }

        if (brand) {
            query.data.map((item) => {
                models.push(item.model);
            });
        }
    }

    return {
        brands: currentBrands.current,
        models,
    };
};
