import {api} from '@/api';
import utils from 'utils';

import {useContext, useState} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';

import {useLabel} from '@/hooks/export';

import {
    Popover,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';

import appContext from '@/appContext';

interface HeaderTopProps {
    handleShowSalesContact: () => void;
}

export const HeaderTop = ({handleShowSalesContact}: HeaderTopProps) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const label = useLabel();

    const {currentUser} = useContext(appContext).userContext;
    const [anchorEl, setAnchorEl] = useState(null);

    const handle = {
        handleOpen(event) {
            setAnchorEl(event.currentTarget);
        },
        handleClose() {
            setAnchorEl(null);
        },
        userLogout() {
            this.handleClose();
            api.user.logout()
                .then(() => queryClient.invalidateQueries({queryKey: ['user']}));
        },
        userProfile() {
            this.handleClose();
            navigate('/profile');
        },
    };

    // todo: move to some config - this values will be static
    const data = {
        phone: '+420 596 013 541',
        list: [
            {name: 'profile', label: label.profile, onClick: () => handle.userProfile()},
            {name: 'logout', label: label.logout, onClick: () => handle.userLogout()},
        ],
        companyName: 'ČEMAT trading spol. s r.o.',
    };

    // todo: phone call as :tel link

    return <div className={cmpClass}>
        <div className={classMap.container}>
            <div className={classMap.title}>
                {data.companyName}
            </div>
            <div className={classMap.menu}>
                <div className={classMap.menuItem}>
                    <a className={classMap.menuLink} href={'tel:+420596013541'}>
                        <i className={classMap.menuIconPhone}></i>
                        {data.phone}
                    </a>
                </div>
                <div className={classMap.menuItem}>
                    <a className={classMap.menuLink} onClick={handleShowSalesContact}>
                        <i className={classMap.menuIconInfo}></i>
                        {label.helpAndSupport}
                    </a>
                </div>
                <div className={classMap.menuItem + (!currentUser.id ? ' ' + classMap.menuItemHidden : '')}>
                    <a className={classMap.menuLink} onClick={handle.handleOpen}>
                        <i className={classMap.menuIconUser}></i>
                        {
                            currentUser.id ?
                                currentUser.firstName + ' ' + currentUser.lastName :
                                ''
                        }
                    </a>
                    <Popover
                        id={open ? 'popover' : undefined}
                        open={!!anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        anchorEl={anchorEl}
                        onClose={handle.handleClose}
                    >
                        <List>
                            {
                                data.list.map((item) => {
                                    return <ListItem disablePadding key={item.name}>
                                        <ListItemButton onClick={() => item.onClick()}>
                                            <ListItemText primary={item.label}/>
                                        </ListItemButton>
                                    </ListItem>;
                                })
                            }
                        </List>
                    </Popover>
                </div>
            </div>
        </div>
    </div>;
};

const cmpClass = 'header-top';
const classMap = utils.generateClassMap(cmpClass, {
    container: 'container container',
    title: 'title',
    menu: 'menu',
    menuItem: 'menu-item',
    menuItemHidden: 'menu-item--hidden',
    menuLink: 'menu-link',
    menuIconPhone: 'menu-icon iconfa-phone',
    menuIconInfo: 'menu-icon iconfa-info',
    menuIconUser: 'menu-icon iconfa-user',
    salesContactBox: 'sales-contact-box',
});
