import {api} from '@/api';

import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import {useQueryClient} from '@tanstack/react-query';

import {useLabel} from '@/hooks/export';
import {
    BigTitle,
    CartList,
    CartNote,
    CartTotal,
    CartContactForm,
    CartContactSummary,
    CatalogInfo,
    Footer,
    Header,
} from '@components/export';

import appContext from '@/appContext';

export const CartPage = (props: {
    step: 'cart' | 'contact'| 'summary',
}) => {
    const label = useLabel();
    const {currentUser} = useContext(appContext).userContext;

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const cartContext = useContext(appContext).cartContext;
    const {contactForm, setContactForm} = useContext(appContext).cartContext;

    const [showErrors, setShowErrors] = useState(false);

    const [termsAccepted, setTermsAccepted] = useState(false);

    const ignoreFields = ['doSaveAddress', 'doSaveContact', 'info', 'info2'];

    const flags = {
        cartHaveProducts:
            cartContext.cart &&
            cartContext.cart.list &&
            Object.entries(cartContext.cart.list).length,
    };

    const handle = {
        dropCart: () => {
            cartContext.clean()
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['cart']});
                });
        },
        sendOrder: () => {
            cartContext.submit()
                .then((resp) => {
                    if(!resp.status) {
                        toast.error(label.orderSendError);
                        return;
                    }
                    toast.success(label.orderIsSent);
                }).catch(() => {
                    toast.error(label.orderSendError);
                });
        },
        saveAddress: () => {
            api.user.saveAddress({
                city: contactForm.execCity,
                account: contactForm.execCompany,
                postal_code: contactForm.execPostalCode,
                street: contactForm.execStreet,
            })
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['savedAddresses']});
                });
        },
        saveContact: () => {
            api.user.saveContact({
                first_name: contactForm.contactFirstName,
                last_name: contactForm.contactLastName,
                email: contactForm.contactEmail,
                phone: contactForm.contactPhone,
            })
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['savedContacts']});
                });
        },
    };

    useEffect(() => {
        if (!flags.cartHaveProducts) {
            toast(label.cartIsEmpty);

            if (props.step !== 'cart') {
                navigate('/catalog');
            }
        }
    }, [cartContext.cart]);

    useEffect(() => {
        if (currentUser) {
            contactForm['invoiceCity'] = currentUser.invoiceCity;
            contactForm['invoiceCompany'] = currentUser.invoiceCompany;
            contactForm['invoicePostalCode'] = currentUser.invoicePostalCode;
            contactForm['invoiceStreet'] = currentUser.invoiceStreet;

            setContactForm({...contactForm});
        }
    }, [currentUser]);

    useEffect(() => {
        window.scrollTo(0, 0);

        setContactForm({
            ...contactForm,
            doSaveAddress: false,
            doSaveContact: false,
        });
    }, [props.step]);

    // todo? split cart steps to single components

    return <>
        <Header/>

        <div className="page-content cart-page">
            {
                props.step === 'cart' ?
                    <>
                        <div className="cart-page-list container">
                            <BigTitle title={label.cart} dark={true} className={'cart-page-list__title'}/>
                            <CartList />
                        </div>

                        {
                            flags.cartHaveProducts ?
                                <div className="cart-page-total container">
                                    <CartTotal
                                        hideTerms
                                        buttons={{
                                            backward: {label: label.dropCart, onClick: () => handle.dropCart()},
                                            forward: {label: label.doOrder, onClick: () => navigate('/cart/contact')},
                                        }}
                                    />
                                </div> : <></>
                        }
                    </> : <></>
            }
            {
                props.step === 'contact' ?
                    <>
                        <div className="cart-page-list container">
                            <BigTitle title={label.order} dark={true} className={'cart-page-list__title'}/>
                            <CartContactForm
                                onChange={(data: {name: string, value: string|boolean}) => {
                                    contactForm[data.name] = data.value;
                                    setContactForm({...contactForm});
                                }}
                                fields={contactForm}
                                showErrors={showErrors}
                            />
                        </div>

                        {
                            flags.cartHaveProducts ?
                                <div className="cart-page-total container">
                                    <CartTotal
                                        hidePrice
                                        hideTerms
                                        buttons={{
                                            backward: {label: label.stepBack, onClick: () => navigate('/cart')},
                                            forward: {label: label.doOrder, onClick: () => {
                                                // fixme? extract body to separate callback
                                                // handle acceptContact

                                                const emptyFields = Object.entries(contactForm).filter((entry) => {
                                                    if (ignoreFields.filter((field) => entry[0] === field).length) {
                                                        return false;
                                                    }

                                                    return !entry[1];
                                                });

                                                setShowErrors(!!emptyFields.length);

                                                if (!emptyFields.length) {
                                                    if (contactForm.doSaveAddress) {
                                                        handle.saveAddress();
                                                    }

                                                    if (contactForm.doSaveContact) {
                                                        handle.saveContact();
                                                    }

                                                    navigate('/cart/summary');
                                                }
                                            }},
                                        }}
                                    />
                                </div> : <></>
                        }
                    </> : <></>
            }
            {
                props.step === 'summary' ?
                    <>
                        <div className="cart-page-list container">
                            <BigTitle title={label.orderSummary} dark={true} className={'cart-page-list__title'}/>
                            <CartContactSummary fields={contactForm}/>

                        </div>
                        <div className="cart-page-list container">
                            <CartList readonly/>
                        </div>

                        {/* cart note container */}
                        <div className="cart-page-list container">
                            <CartNote />
                        </div>

                        {
                            flags.cartHaveProducts ?
                                <div className="cart-page-total container">
                                    <CartTotal
                                        buttons={{
                                            backward: {label: label.stepBack, onClick: () => navigate('/cart/contact')},
                                            forward: {label: label.doBindingOrder, onClick: () => {
                                                handle.sendOrder();
                                                handle.dropCart();
                                                navigate('/catalog');
                                            }},
                                        }}
                                        onChangeTerms={(accepted) => setTermsAccepted(accepted)}
                                        blockForward={!termsAccepted}
                                    />
                                </div> : <></>
                        }
                    </> : <></>
            }

            <CatalogInfo/>
        </div>

        <Footer/>
    </>;
};
