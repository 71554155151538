import utils from 'utils';

import {useState, useEffect} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import {FilterList} from '@components/export';
import {useLabel} from '@/hooks/export';

export const CatalogFilters = (props: {
    purpose: number,
}) => {
    const label = useLabel();

    const isDesktop = useMediaQuery('(min-width: 1024px)');
    const [show, setShow] = useState(!!props.purpose);

    useEffect(() => {
        setShow(isDesktop);
    }, [isDesktop]);

    return <div className={cmpClass}>
        <div className={classMap.top}>
            <p className={'title'}>
                {label.filtering}
            </p>
            <span
                onClick={() => setShow(!show)}
                className={classMap.toggleFilters}
            >
                {
                    !show ?
                        <><i className={'iconfa-arrowDown'}></i>{label.activeFilters}</> :
                        <><i className={'iconfa-arrowUp'}></i>{label.hideFilters}</>
                }
            </span>
        </div>

        {
            // fixme: prop drill

            show ?
                <FilterList purpose={props.purpose} /> :
                <></>
        }
    </div>;
};

const cmpClass = 'catalog-filters';
const classMap = utils.generateClassMap(cmpClass, {
    top: 'top',
    toggleFilters: 'toggle-filters',
    clearFilters: 'clear-filters',
});
