import utils from 'utils';

export const UiBadge = (props: {
    title: string,
    onClick: () => void
}) => {
    return <div className={cmpClass}>
        {
            <div
                className={classMap.button}
                onClick={() => props.onClick()}
            >
                <span>
                    {props.title}
                </span>
                <div className={classMap.icon}>
                    <i className='iconfa-close'></i>
                </div>
            </div>
        }
    </div>;
};

const cmpClass = 'ui-badge';

const classMap = utils.generateClassMap(cmpClass, {
    icon: 'icon',
    button: 'button',
});
