import utils from 'utils';

import {useEshopConfig} from '@/hooks/export';

export const CartContactSummary = (props: {
    fields: any // todo: any are you okay
}) => {
    const {layout} = useEshopConfig().cartContactForm;

    return <div className={cmpClass}>
        {
            layout.map((group, groupI) => {
                return <div className={classMap.group} key={groupI}>
                    <p className={classMap.title}>
                        {group.title}
                    </p>
                    <div className={classMap.grid}>
                        {
                            group.grid.map((item, itemI) => {
                                return item.type === 'inputText' ?
                                    <div className={classMap.gridItem} key={itemI}>
                                        <span>{item.label}:</span>
                                        <span>{props.fields[item.name]}</span>
                                    </div> :
                                    <></>;
                            })
                        }
                    </div>
                </div>;
            })
        }
    </div>;
};

const cmpClass = 'cart-contact-summary';
const classMap = utils.generateClassMap(cmpClass, {
    checkbox: 'checkbox',
    grid: 'grid',
    gridItem: 'grid-item',
    group: 'group',
    inputText: 'input-text',
    select: 'select',
    text: 'text',
    title: 'title',
});
