import {useContext} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {useNavigate} from 'react-router-dom';
import Tooltip from '@mui/joy/Tooltip';
import toast from 'react-hot-toast';

import utils from 'utils';
import {OrderBadge} from '@components/export';
import appContext from '@/appContext';

export const OrderListItem = (props: {
    handle: any,
    label: any,
    order: any,
}) => {
    const {handle, label, order} = props;
    const cart = useContext(appContext).cartContext;
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const formattedPrice = {
        price: utils.formatPrice(order.price),
    };

    /**
     * handleOrderAgain
     * @param {string} id
     * @param {any} e
     */
    const handleOrderAgain = async (id: string, e: any) => {
        // prevent redirect to order page
        e.stopPropagation();
        e.preventDefault();

        // TODO: optimize and complete this function ---> WIP only..

        const response = await utils.fetch('web/order/' + id, false);

        if (!response.status || !response.data) {
            console.error('order again rest get error', response);
            return;
        }

        const data = response.data;
        const additionalServices = {};

        data.lines.forEach((line: any) => {
            if (line.additionalServices && Object.keys(line.additionalServices).length > 0) {
                Object.values(line.additionalServices).forEach((item: additionalService) => {
                    if (item?.id) {
                        additionalServices[item?.id] = item;
                    }
                });
            }
        });

        // filter out lines with that are additional services and add qty to services
        data.lines = data.lines.filter((line: any) => {
            if (additionalServices[line.productTemplateId] && !isNaN(parseInt(line.qty))) {
                additionalServices[line.productTemplateId].qty += parseInt(line.qty);
                return false;
            }
            return true;
        });

        let cnt = 0;
        data.lines.forEach((line: any) => {
            Object.entries(line.additionalServices).map((item) => {
                if (additionalServices[item[0]]?.id) {
                    line.additionalServices[item[0]].qty = 1;
                }
            });

            cart.updateItem(line.productTemplateId, line.qty, line.additionalServices)
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['cart']});

                    toast.remove();
                    toast.success(label.cartIsUpToDate);

                    // navigate to cart after all items are added
                    if (cnt === data.lines.length - 1) {
                        cnt = 0;
                        setTimeout(() => {
                            navigate('/cart');
                        }, 800);
                    }
                    cnt++;
                });
        });
    };

    return (
        <div className={cmpClass}>
            <div className={classMap.row} onClick={(e) => handle.navigateToOrder(order.id, e)}>
                <div className={classMap.box}>
                    <div className={classMap.title}>{order.name} <span className={classMap.orderNum}>
                        ({order.orderNum ?? label.orderCreatePending})
                    </span></div>
                </div>

                <div className={`${classMap.box} ${classMap.boxBadge}`}>
                    <OrderBadge state={order.status} color={order.statusColor} />
                </div>

                <div className={classMap.box}>
                    <div className={classMap.created}>
                        {label.orderCreatedDate + ': ' + utils.getDate(order.dateCreated, false)}
                    </div>
                </div>

                <div className={classMap.box}>
                    <div className={classMap.createdBy}>
                        {label.orderCreatedBy + ': ' + (order.cratedBy ?? label.unknown)}
                    </div>
                </div>

                <div className={classMap.box}>
                    <div className={classMap.price}>
                        <span className={classMap.priceCurrent}>
                            {formattedPrice.price + ' ' + label.currency}
                        </span>
                    </div>
                </div>

                <div className={classMap.box}>
                    <Tooltip title={label.orderAgain} variant="soft" arrow>
                        <div className={classMap.orderAgainBtn + ' iconfa-repeatArrow'} onClick={(e) => handleOrderAgain(order.id, e)} />
                    </Tooltip>
                </div>
            </div>
        </div>);
};

const cmpClass = 'order-list-item';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    title: 'title',
    price: 'price',
    product: 'product',
    priceCurrent: 'price-current',
    row: 'row',
    created: 'created',
    createdBy: 'created-by',
    orderNum: 'order-num',
    boxBadge: 'box-badge',
    orderAgainBtn: 'order-again-btn',
});
