import {useState} from 'react';

import {useLabel} from '@/hooks/export';

import {TextField} from '@mui/material';
import {DatePicker, LocalizationProvider, PickersDay} from '@mui/x-date-pickers';
import {styled} from '@mui/material/styles';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

import moment from 'moment';
import 'moment/dist/locale/cs';

export const UiWeekPicker = (props: {
    value: any,
    onChange: (value) => void,
    label: string,
}) => {
    const [hoveredDay, setHoveredDay] = useState(null);

    return <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale="cs"
    >
        <DatePicker
            value={moment(props.value)}
            onChange={(value) => props.onChange(value)}
            label={props.label}
            minDate={moment().add(2, 'weeks').isoWeekday(1)}
            showDaysOutsideCurrentMonth
            displayWeekNumber
            slots={{
                day: Day,
                textField: CustomTextInput,
            }}
            slotProps={{
                day: (ownerState) => ({
                    selectedDay: moment(props.value),
                    hoveredDay,
                    onPointerEnter: () => setHoveredDay(ownerState.day),
                    onPointerLeave: () => setHoveredDay(null),
                }),
            }}
            className={cmpClass}
        />
    </LocalizationProvider>;
};

/**
 *
 * @param dayA
 * @param dayB
 */
const isInSameWeek = (dayA, dayB) => {
    return !dayB ?
        false :
        dayA.isSame(dayB, 'week');
};

const CustomTextInput = (props: any) => {
    const label = useLabel();

    const format = 'DoMM';
    const dateObj = moment(props.value, 'DD.MM.YYYY');

    const value = dateObj.isValid() ?
        dateObj.weekday(0).format(format) + ' - ' + dateObj.weekday(6).format(format) :
        label.selectWeek;

    return <TextField
        {...props}
        value={value}
    />;
};

const Day = (props: any) => { // fixme any
    const {day, selectedDay, hoveredDay, ...other} = props;

    return (
        <CustomPickersDay
            {...other}
            day={day}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    );
};

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
    // @ts-ignore fixme
})(({theme, isSelected, isHovered, day}) => (
    {
        borderRadius: 0,
        ...(isSelected && {
            'backgroundColor': theme.palette.primary.main,
            'color': theme.palette.primary.contrastText,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.main,
            },
        }),
        ...(isHovered && {
            'backgroundColor': theme.palette.primary[theme.palette.mode],
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary[theme.palette.mode],
            },
        }),
        ...(day.day() === 1 && {
            borderTopLeftRadius: '20%',
            borderBottomLeftRadius: '20%',
        }),
        ...(day.day() === 0 && {
            borderTopRightRadius: '20%',
            borderBottomRightRadius: '20%',
        }),
    }
));

const cmpClass = 'ui-week-picker';
