import React from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Toaster, ToastBar, toast} from 'react-hot-toast';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

import {AppContextProvider} from '@/appContext';

import '@sass/index.scss';

// import PopupsWrapper from '@components/Popup/PopupsWrapper';

const queryClient = new QueryClient();

export default function App(props) {
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <AppContextProvider>
                    {/* render wrapped component */}
                    {/* {props.children} */}
                    {React.cloneElement(props.children, {queryClient: queryClient})}

                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                    {/* todo: move Toaster to own component */}
                    <Toaster
                        position='bottom-right'
                        containerClassName='toasterContainer'
                        toastOptions={{
                            className: 'toasterClass',
                            duration: 2500,
                            success: {
                                className: 'toasterClass success',
                            },
                            error: {
                                className: 'toasterClass error',
                                duration: 4000,
                            },
                        }}
                    >
                        {
                            (t) => (
                                <div onClick={() => toast.dismiss(t.id)}>
                                    <ToastBar toast={t}/>
                                </div>
                            )
                        }
                    </Toaster>
                    {/* <PopupsWrapper />*/}
                    <ReactQueryDevtools initialIsOpen={false} />
                </AppContextProvider>
            </QueryClientProvider>
        </>
    );
}
